import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

const storeName = 'lookup';

export interface LookupState {
  disposalError: string;
  disposalItems: any[];
  disposalLoading: boolean;
  userError: string;
  userItems: any[];
  userLoading: boolean;
}

const initialState: LookupState = {
  disposalError: '',
  disposalItems: [],
  disposalLoading: false,
  userError: '',
  userItems: [],
  userLoading: false,
};

const lookupSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    clearDisposals(state: LookupState) {
      state.disposalItems = [];
    },
    getDisposalsPreRequest(state: LookupState) {
      state.disposalError = '';
      state.disposalLoading = true;
      state.disposalItems = [];
    },
    getDisposalsSuccess(state: LookupState, action: PayloadAction<any>) {
      state.disposalItems = action.payload.data;
      state.disposalLoading = false;
    },
    getDisposalsFailure(state: LookupState, action: PayloadAction<string>) {
      state.disposalError = action.payload;
      state.disposalLoading = false;
    },

    clearUsers(state: LookupState) {
      state.userItems = [];
    },
    getUsersPreRequest(state: LookupState) {
      state.userError = '';
      state.userLoading = true;
      state.userItems = [];
    },
    getUsersSuccess(state: LookupState, action: PayloadAction<any>) {
      state.userItems = action.payload.data;
      state.userLoading = false;
    },
    getUsersFailure(state: LookupState, action: PayloadAction<string>) {
      state.userError = action.payload;
      state.userLoading = false;
    },
  },
});

export const lookupActions = {
  clearDisposals: lookupSlice.actions.clearDisposals,
  getDisposalsPreRequest: lookupSlice.actions.getDisposalsPreRequest,
  getDisposalsRequest: createAction<string>(`${storeName}/getDisposalsRequest`),
  getDisposalsSuccess: lookupSlice.actions.getDisposalsSuccess,
  getDisposalsFailure: lookupSlice.actions.getDisposalsFailure,

  clearUsers: lookupSlice.actions.clearUsers,
  getUsersPreRequest: lookupSlice.actions.getUsersPreRequest,
  getUsersRequest: createAction<string>(`${storeName}/getUsersRequest`),
  getUsersSuccess: lookupSlice.actions.getUsersSuccess,
  getUsersFailure: lookupSlice.actions.getUsersFailure,
};

export const lookupReducer = lookupSlice.reducer;
