import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AreaCountriesState } from '@redux/types/common/area-countries/areaCountriesTypes';
import { SocietySegments } from '@shared/types/common/segment';

const storeName = 'areaCountries';

const initialState: AreaCountriesState = {
  loading: false,
  error: null,
  countries: [],
};

const areaCountriesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setAreaCountriesLoading(state: AreaCountriesState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getAreaCountriesSuccess(state: AreaCountriesState, action: PayloadAction<SocietySegments[]>) {
      state.countries = action.payload;
      state.loading = false;
    },
    getAreaCountriesFailure(state: AreaCountriesState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const areaCountriesActions = {
  setAreaCountriesLoading: areaCountriesSlice.actions.setAreaCountriesLoading,
  getAreaCountriesSuccess: areaCountriesSlice.actions.getAreaCountriesSuccess,
  getAreaCountriesFailure: areaCountriesSlice.actions.getAreaCountriesFailure,
  getAreaCountriesRequest: createAction(`${storeName}/getAreaCountriesRequest`),
};

export const areaCountriesReducer = areaCountriesSlice.reducer;
