import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CampaignRecipientsState, IncludeExcludeRecipient } from '@redux/types/campaigns/campaignRecipientsTypes';
import {
  CampaignRecipientItem,
  GetCampaignRecipientsSuccessPayload,
} from '@redux/types/campaigns/manageCampaignSinglePropertyTypes';
import { GetCampaignRecipientsRequestPayload } from '@redux/types/campaigns/manageCampaignTypes';

const storeName = 'campaignRecipients';

const initialState: CampaignRecipientsState = {
  loading: false,
  list: [],
  included: [],
  excluded: [],
  updateLoading: false,
};

const campaignRecipientsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setRecipientsLoading(state: CampaignRecipientsState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.list = [];
      state.meta = undefined;
    },
    getRecipientsSuccess(state: CampaignRecipientsState, action: PayloadAction<GetCampaignRecipientsSuccessPayload>) {
      state.loading = false;
      state.list = action.payload.data;
      state.meta = action.payload.meta;
    },
    getRecipientsFailure(state: CampaignRecipientsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.list = [];
      state.meta = undefined;
    },
    clearRecipientsList(state: CampaignRecipientsState) {
      state.list = [];
      state.meta = undefined;
    },
    setUpdatedRecipientsLoading(state: CampaignRecipientsState, action: PayloadAction<boolean>) {
      state.updateLoading = action.payload;
    },
    getUpdatedRecipientsSuccess(
      state: CampaignRecipientsState,
      action: PayloadAction<GetCampaignRecipientsSuccessPayload>
    ) {
      state.list = [...state.list, ...action.payload.data];
      state.updateLoading = false;
      state.meta = action.payload.meta;
    },
    getUpdatedRecipientsFailure(state: CampaignRecipientsState, action: PayloadAction<string>) {
      state.list = [];
      state.meta = undefined;
      state.updateLoading = false;
      state.error = action.payload;
    },
    excludeRecipient(state: CampaignRecipientsState, action: PayloadAction<IncludeExcludeRecipient>) {
      const includedSet = new Set(state.included);

      if (includedSet.has(action.payload.recipient.id)) {
        state.included = state.included.filter((id) => id !== action.payload.recipient.id);
      } else {
        state.excluded = Array.from(new Set([...state.excluded, action.payload.recipient.id]));
      }

      if (action.payload.isSearching) {
        state.list = state.list.map((recipient) => {
          if (recipient.id === action.payload.recipient.id) {
            return {
              ...recipient,
              included_in_recipient_list: false,
            };
          }

          return recipient;
        });
      } else {
        state.list = state.list.filter(
          (recipient: CampaignRecipientItem) => recipient.id != action.payload.recipient.id
        );
      }
    },
    includeRecipient(state: CampaignRecipientsState, action: PayloadAction<IncludeExcludeRecipient>) {
      const excludedSet = new Set(state.excluded);

      if (excludedSet.has(action.payload.recipient.id)) {
        state.excluded = state.excluded.filter((id) => id !== action.payload.recipient.id);
      } else {
        state.included = Array.from(new Set([...state.included, action.payload.recipient.id]));
      }

      state.list = state.list.map((recipient) => {
        if (recipient.id == action.payload.recipient.id) {
          return {
            ...recipient,
            included_in_recipient_list: true,
          };
        }

        return recipient;
      });
    },
    setIncludedAndExcludedRecipients(
      state: CampaignRecipientsState,
      action: PayloadAction<{ included: Id[]; excluded: Id[] }>
    ) {
      state.excluded = action.payload.excluded;
      state.included = action.payload.included;
    },
  },
});

export const campaignRecipientsActions = {
  setRecipientsLoading: campaignRecipientsSlice.actions.setRecipientsLoading,
  getRecipientsSuccess: campaignRecipientsSlice.actions.getRecipientsSuccess,
  getRecipientsFailure: campaignRecipientsSlice.actions.getRecipientsFailure,
  getRecipientsRequest: createAction<GetCampaignRecipientsRequestPayload>(`${storeName}/getRecipientsRequest`),
  clearRecipientsList: campaignRecipientsSlice.actions.clearRecipientsList,
  setUpdatedRecipientsLoading: campaignRecipientsSlice.actions.setUpdatedRecipientsLoading,
  getUpdatedRecipientsSuccess: campaignRecipientsSlice.actions.getUpdatedRecipientsSuccess,
  getUpdatedRecipientsFailure: campaignRecipientsSlice.actions.getUpdatedRecipientsFailure,
  getUpdatedRecipientsRequest: createAction<GetCampaignRecipientsRequestPayload>(
    `${storeName}/getUpdatedRecipientsRequest`
  ),
  includeRecipient: campaignRecipientsSlice.actions.includeRecipient,
  excludeRecipient: campaignRecipientsSlice.actions.excludeRecipient,
  setIncludedAndExcludedRecipients: campaignRecipientsSlice.actions.setIncludedAndExcludedRecipients,
};

export const campaignRecipientsReducer = campaignRecipientsSlice.reducer;
