import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DisposalMarketplacePerformanceState,
  GetDisposalMarketplacePerformanceSuccess,
} from '@redux/types/disposal/disposalMarketplacePerformance';

const storeName = 'disposalMarketplacePerformance';

const initialState: DisposalMarketplacePerformanceState = {
  loading: false,
  error: null,
  reports: [],
};

const disposalMarketplacePerformanceSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setLoading(state: DisposalMarketplacePerformanceState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getDataSuccess(
      state: DisposalMarketplacePerformanceState,
      action: PayloadAction<GetDisposalMarketplacePerformanceSuccess>
    ) {
      state.reports = action.payload.data;
      state.loading = false;
    },
    getDataFailure(state: DisposalMarketplacePerformanceState, action: PayloadAction<string>) {
      state.reports = [];
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const disposalMarketplacePerformanceActions = {
  setLoading: disposalMarketplacePerformanceSlice.actions.setLoading,
  getDataSuccess: disposalMarketplacePerformanceSlice.actions.getDataSuccess,
  getDataFailure: disposalMarketplacePerformanceSlice.actions.getDataFailure,
  getDataRequest: createAction<Id>(`${storeName}/getDataRequest`),
};

export const disposalMarketplacePerformanceReducer = disposalMarketplacePerformanceSlice.reducer;
