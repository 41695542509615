import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewDisposalsInsightsSaga,
  deleteSavedViewDisposalsInsightsSaga,
  editSavedViewDisposalsInsightsSaga,
  getDisposalsInsightsByIdSaga,
  getDisposalsInsightsSaga,
  pinDisposalsInsightsSavedViewsSaga,
  unpinDisposalsInsightsSavedViewsSaga,
  updatePinnedDisposalsInsightsSavedViewsSaga,
} from '@redux/sagas/saved_view/insights/savedViewDisposalsInsightsSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewDisposalsInsights';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewDisposalsInsightsActions = generateActions(storeName, slice);

export const savedViewDisposalsInsightsReducer = slice.reducer;

export const savedViewDisposalsInsightsSagas: [ActionPattern, any][] = [
  [savedViewDisposalsInsightsActions.getListRequest.type, getDisposalsInsightsSaga],
  [savedViewDisposalsInsightsActions.getUnpinnedViewRequest.type, getDisposalsInsightsByIdSaga],
  [savedViewDisposalsInsightsActions.updateSavedViewsRequest.type, updatePinnedDisposalsInsightsSavedViewsSaga],
  [savedViewDisposalsInsightsActions.deleteViewRequest.type, deleteSavedViewDisposalsInsightsSaga],
  [savedViewDisposalsInsightsActions.editViewRequest.type, editSavedViewDisposalsInsightsSaga],
  [savedViewDisposalsInsightsActions.createViewRequest.type, createSavedViewDisposalsInsightsSaga],
  [savedViewDisposalsInsightsActions.pinViewRequest.type, pinDisposalsInsightsSavedViewsSaga],
  [savedViewDisposalsInsightsActions.unpinViewRequest.type, unpinDisposalsInsightsSavedViewsSaga],
];
