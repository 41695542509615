import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CompaniesState,
  GetCompaniesRequestPayload,
  GetCompaniesSuccessPayload,
} from '@redux/types/common/companies/companiesTypes';

const storeName = 'companies';

const initialState: CompaniesState = {
  companiesLoading: false,
  error: null,
  companies: [],
  pagination: undefined,
};

const companiesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setCompaniesLoading(state: CompaniesState, action: PayloadAction<boolean>) {
      state.companiesLoading = action.payload;
    },
    getCompaniesSuccess(state: CompaniesState, action: PayloadAction<GetCompaniesSuccessPayload>) {
      state.companies = action.payload.data;
      state.pagination = action.payload.meta.pagination;
      state.companiesLoading = false;
    },
    getCompaniesFailure(state: CompaniesState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.companiesLoading = false;
      state.companies = [];
    },
    clearCompaniesList(state: CompaniesState) {
      state.companies = [];
      state.pagination = undefined;
    },
  },
});

export const companiesActions = {
  setCompaniesLoading: companiesSlice.actions.setCompaniesLoading,
  getCompaniesSuccess: companiesSlice.actions.getCompaniesSuccess,
  getCompaniesFailure: companiesSlice.actions.getCompaniesFailure,
  clearCompaniesList: companiesSlice.actions.clearCompaniesList,
  getCompaniesRequest: createAction<GetCompaniesRequestPayload>(`${storeName}/getCompaniesRequest`),
};

export const companiesReducer = companiesSlice.reducer;
