import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ChangeInsightsTaskStatusRequestPayload,
  GetInsightsRequestPayload,
  GetInsightsSuccessPayload,
  GetInsightsTableSuccessPayload,
  GetInsightsTaskRequestPayload,
  SendEmailConfirmationRequestPayload,
} from '@redux/types/insights/viewings/insightsViewingsTypes';
import { InsightsViewingsState } from '@redux/types/insights/viewings/insightsViewingsTypes';
import { InsightsViewingTask } from '@shared/models/insightsViewing/insightsViewingTask';

const storeName = 'insightsViewings';

const initialState: InsightsViewingsState = {
  loading: false,
  error: null,
  tableLoading: false,
  viewings_month: [],
  viewings_sector: [],
  viewings_source: [],
  viewings_table_data: [],
  viewings_table_meta: undefined,
  viewings_user: [],
  viewings_weekly: [],
  taskLoading: false,
  action: false,
  task: undefined,
  taskError: null,
  deletingTask: false,
  deletingError: null,
  changingStatusLoading: false,
  changingStatusError: null,
  sendingEmailConfirmationLoading: false,
  sendingEmailConfirmationSuccess: false,
  sendingEmailConfirmationError: null,
};

const insightsViewingsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setListLoading(state: InsightsViewingsState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getListSuccess(state: InsightsViewingsState, action: PayloadAction<GetInsightsSuccessPayload>) {
      state.viewings_month = action.payload.viewings_month;
      state.viewings_sector = action.payload.viewings_sector;
      state.viewings_source = action.payload.viewings_source;
      state.viewings_table_data = action.payload.viewings_table_data;
      state.viewings_table_meta = action.payload.viewings_table_meta;
      state.viewings_user = action.payload.viewings_user;
      state.viewings_weekly = action.payload.viewings_weekly;
      state.loading = false;
    },
    getListFailure(state: InsightsViewingsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
    setTableLoading(state: InsightsViewingsState, action: PayloadAction<boolean>) {
      state.tableLoading = action.payload;
    },
    getTableSuccess(state: InsightsViewingsState, action: PayloadAction<GetInsightsTableSuccessPayload>) {
      state.viewings_table_data = action.payload.data;
      state.viewings_table_meta = action.payload.meta;
      state.tableLoading = false;
    },
    getTableFailure(state: InsightsViewingsState, action: PayloadAction<string>) {
      state.tableLoading = false;
      state.error = action.payload;
    },
    setTaskLoading(state: InsightsViewingsState, action: PayloadAction<boolean>) {
      state.taskLoading = action.payload;
    },
    getTaskSuccess(state: InsightsViewingsState, action: PayloadAction<InsightsViewingTask>) {
      state.task = action.payload;
      state.taskLoading = false;
    },
    getTaskFailure(state: InsightsViewingsState, action: PayloadAction<string>) {
      state.taskError = action.payload;
      state.taskLoading = false;
    },
    setDeleteTaskLoading(state: InsightsViewingsState, action: PayloadAction<boolean>) {
      state.deletingTask = action.payload;
      state.action = action.payload;
    },
    deleteTaskSuccess(state: InsightsViewingsState, action: PayloadAction<Id>) {
      state.deletingTask = false;
      state.action = false;
      state.viewings_table_data = state.viewings_table_data.filter((task) => task.task_id !== action.payload);
    },
    deleteTaskFailure(state: InsightsViewingsState, action: PayloadAction<string>) {
      state.deletingError = action.payload;
      state.deletingTask = false;
      state.action = false;
    },
    changeTaskStatusLoading(state: InsightsViewingsState, action: PayloadAction<boolean>) {
      state.changingStatusLoading = action.payload;
      state.action = action.payload;
    },
    changeTaskStatusSuccess(state: InsightsViewingsState, action: PayloadAction<InsightsViewingTask>) {
      state.task = { ...state.task, ...action.payload };
      state.changingStatusLoading = false;
      state.action = false;
    },
    changeTaskStatusFailure(state: InsightsViewingsState, action: PayloadAction<string>) {
      state.changingStatusError = action.payload;
      state.changingStatusLoading = false;
      state.action = false;
    },
    setSendEmailLoading(state: InsightsViewingsState, action: PayloadAction<boolean>) {
      state.sendingEmailConfirmationLoading = action.payload;
    },
    sendEmailSuccess(state: InsightsViewingsState) {
      state.sendingEmailConfirmationLoading = false;
      state.sendingEmailConfirmationSuccess = true;
    },
    sendEmailFailure(state: InsightsViewingsState, action: PayloadAction<string>) {
      state.sendingEmailConfirmationLoading = false;
      state.sendingEmailConfirmationSuccess = false;
      state.sendingEmailConfirmationError = action.payload;
    },
    sendEmailClearError(state: InsightsViewingsState) {
      state.sendingEmailConfirmationLoading = false;
      state.sendingEmailConfirmationSuccess = false;
      state.sendingEmailConfirmationError = null;
    },
  },
});

export const insightsViewingsActions = {
  getListRequest: createAction<GetInsightsRequestPayload>(`${storeName}/getListRequest`),
  setListLoading: insightsViewingsSlice.actions.setListLoading,
  getListSuccess: insightsViewingsSlice.actions.getListSuccess,
  getListFailure: insightsViewingsSlice.actions.getListFailure,
  getTableRequest: createAction<GetInsightsRequestPayload>(`${storeName}/getTableRequest`),
  setTableLoading: insightsViewingsSlice.actions.setTableLoading,
  getTableSuccess: insightsViewingsSlice.actions.getTableSuccess,
  getTableFailure: insightsViewingsSlice.actions.getTableFailure,
  getTaskRequest: createAction<GetInsightsTaskRequestPayload>(`${storeName}/getTaskRequest`),
  setTaskLoading: insightsViewingsSlice.actions.setTaskLoading,
  getTaskSuccess: insightsViewingsSlice.actions.getTaskSuccess,
  getTaskFailure: insightsViewingsSlice.actions.getTaskFailure,
  deleteTaskRequest: createAction<Id>(`${storeName}/deleteTaskRequest`),
  setDeleteTaskLoading: insightsViewingsSlice.actions.setDeleteTaskLoading,
  deleteTaskSuccess: insightsViewingsSlice.actions.deleteTaskSuccess,
  deleteTaskFailure: insightsViewingsSlice.actions.deleteTaskFailure,
  changeTaskStatusRequest: createAction<ChangeInsightsTaskStatusRequestPayload>(`${storeName}/changeTaskStatusRequest`),
  changeTaskStatusLoading: insightsViewingsSlice.actions.changeTaskStatusLoading,
  changeTaskStatusSuccess: insightsViewingsSlice.actions.changeTaskStatusSuccess,
  changeTaskStatusFailure: insightsViewingsSlice.actions.changeTaskStatusFailure,
  sendEmailRequest: createAction<SendEmailConfirmationRequestPayload>(`${storeName}/sendEmailRequest`),
  setSendEmailLoading: insightsViewingsSlice.actions.setSendEmailLoading,
  sendEmailSuccess: insightsViewingsSlice.actions.sendEmailSuccess,
  sendEmailFailure: insightsViewingsSlice.actions.sendEmailFailure,
  sendEmailClearError: insightsViewingsSlice.actions.sendEmailClearError,
};

export const insightsViewingsReducer = insightsViewingsSlice.reducer;
