import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewSaleInsightsSaga,
  deleteSavedViewSaleInsightsSaga,
  editSavedViewSaleInsightsSaga,
  getSaleInsightsByIdSaga,
  getSaleInsightsSaga,
  pinSaleInsightsSavedViewsSaga,
  unpinSaleInsightsSavedViewsSaga,
  updatePinnedSaleInsightsSavedViewsSaga,
} from '@redux/sagas/saved_view/insights/savedViewSaleInsightsSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewSaleInsights';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewSaleInsightsActions = generateActions(storeName, slice);

export const savedViewSaleInsightsReducer = slice.reducer;

export const savedViewSaleInsightsSagas: [ActionPattern, any][] = [
  [savedViewSaleInsightsActions.getListRequest.type, getSaleInsightsSaga],
  [savedViewSaleInsightsActions.getUnpinnedViewRequest.type, getSaleInsightsByIdSaga],
  [savedViewSaleInsightsActions.updateSavedViewsRequest.type, updatePinnedSaleInsightsSavedViewsSaga],
  [savedViewSaleInsightsActions.deleteViewRequest.type, deleteSavedViewSaleInsightsSaga],
  [savedViewSaleInsightsActions.editViewRequest.type, editSavedViewSaleInsightsSaga],
  [savedViewSaleInsightsActions.createViewRequest.type, createSavedViewSaleInsightsSaga],
  [savedViewSaleInsightsActions.pinViewRequest.type, pinSaleInsightsSavedViewsSaga],
  [savedViewSaleInsightsActions.unpinViewRequest.type, unpinSaleInsightsSavedViewsSaga],
];
