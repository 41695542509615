import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SocietySegmentsState } from '@redux/types/common/society-segments/societySegmentsTypes';
import { SocietySegments } from '@shared/types/common/segment';

const storeName = 'societySegments';

const initialState: SocietySegmentsState = {
  error: null,
  loading: false,
  segments: [],
};

const societySegmentsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setSocietySegmentsLoading(state: SocietySegmentsState) {
      state.error = null;
      state.loading = true;
      state.segments = [];
    },
    getSocietySegmentsSuccess(state: SocietySegmentsState, action: PayloadAction<SocietySegments[]>) {
      state.loading = false;
      state.segments = action.payload;
    },
    getSocietySegmentsFailure(state: SocietySegmentsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const societySegmentActions = {
  setSocietySegmentsLoading: societySegmentsSlice.actions.setSocietySegmentsLoading,
  getSocietySegmentsSuccess: societySegmentsSlice.actions.getSocietySegmentsSuccess,
  getSocietySegmentsFailure: societySegmentsSlice.actions.getSocietySegmentsFailure,
  getSocietySegmentsRequest: createAction(`${storeName}/getSocietySegmentsRequest`),
};

export const societySegmentsReducer = societySegmentsSlice.reducer;
