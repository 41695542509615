import { combineReducers } from 'redux';

import { disposalsInsightsReducer } from './disposals-insights/disposalsInsightsReducer';
import { lettingInsightsReducer } from './letting/lettingInsightsReducer';
import { insightsRequirementsReducer } from './requirements/requirementsInsightsReducer';
import { saleInsightsReducer } from './sale/saleInsightsReducer';
import { societyAcquisitionInsightsReducer } from './society-acquisition/societyAcquisitionInsightsReducer';
import { societyDisposalInsightsReducer } from './society-disposal/societyDisposalInsightsReducer';
import { insightsViewingsReducer } from './viewings/insightsViewingsReducer';
import { viewingsInsightsReducer } from './viewings-insights/viewingsInsightsReducer';

const insightsReducer = combineReducers({
  requirements: insightsRequirementsReducer,
  viewings: insightsViewingsReducer,

  acquisitions: societyAcquisitionInsightsReducer,
  disposals: societyDisposalInsightsReducer,
  societyViewings: viewingsInsightsReducer,
  lettingsTransactions: lettingInsightsReducer,
  salesTransactions: saleInsightsReducer,
  disposalsInsights: disposalsInsightsReducer,
});

export default insightsReducer;
