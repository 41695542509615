import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CampaignRealtimeResult,
  CampaignRecipientsResult,
  CampaignResultsState,
  GetCampaignReportTableParams,
} from '@redux/types/campaigns/campaignResultsTypes';
import { GetLettingInsightsSuccess } from '@redux/types/insights/letting/lettingInsightsTypes';

const storeName = 'campaignResults';

const initialState: CampaignResultsState = {
  loading: false,
  reports: undefined,
  recipientsResults: undefined,
  recipientsLoading: false,
  realtimeResults: undefined,
  realtimeLoading: false,
};

const campaignResultsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setResultsLoading(state: CampaignResultsState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.reports = undefined;
      state.error = undefined;
    },
    getResultsSuccess(state: CampaignResultsState, action: PayloadAction<GetLettingInsightsSuccess>) {
      state.loading = false;
      state.reports = action.payload.data;
    },
    getResultsFailure(state: CampaignResultsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.reports = undefined;
    },

    setRecipientsResultsLoading(state: CampaignResultsState, action: PayloadAction<boolean>) {
      state.recipientsLoading = action.payload;
      state.recipientsResults = undefined;
    },
    getRecipientsResultsSuccess(state: CampaignResultsState, action: PayloadAction<CampaignRecipientsResult>) {
      state.recipientsLoading = false;
      state.recipientsResults = action.payload;
    },
    getRecipientsResultsFailure(state: CampaignResultsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.recipientsLoading = false;
      state.recipientsResults = undefined;
    },

    setRealtimeResultsLoading(state: CampaignResultsState, action: PayloadAction<boolean>) {
      state.realtimeLoading = action.payload;
      state.realtimeResults = undefined;
    },
    getRealtimeResultsSuccess(state: CampaignResultsState, action: PayloadAction<CampaignRealtimeResult>) {
      state.realtimeLoading = false;
      state.realtimeResults = action.payload;
    },
    getRealtimeResultsFailure(state: CampaignResultsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.realtimeLoading = false;
      state.realtimeResults = undefined;
    },
  },
});

export const campaignResultsActions = {
  setResultsLoading: campaignResultsSlice.actions.setResultsLoading,
  getResultsSuccess: campaignResultsSlice.actions.getResultsSuccess,
  getResultsFailure: campaignResultsSlice.actions.getResultsFailure,
  getResultsRequest: createAction<Id>(`${storeName}/getResultsRequest`),

  setRecipientsResultsLoading: campaignResultsSlice.actions.setRecipientsResultsLoading,
  getRecipientsResultsSuccess: campaignResultsSlice.actions.getRecipientsResultsSuccess,
  getRecipientsResultsFailure: campaignResultsSlice.actions.getRecipientsResultsFailure,
  getRecipientsResultsRequest: createAction<GetCampaignReportTableParams>(`${storeName}/getRecipientsResultsRequest`),

  setRealtimeResultsLoading: campaignResultsSlice.actions.setRealtimeResultsLoading,
  getRealtimeResultsSuccess: campaignResultsSlice.actions.getRealtimeResultsSuccess,
  getRealtimeResultsFailure: campaignResultsSlice.actions.getRealtimeResultsFailure,
  getRealtimeResultsRequest: createAction<GetCampaignReportTableParams>(`${storeName}/getRealtimeResultsRequest`),
};

export const campaignResultsReducer = campaignResultsSlice.reducer;
