import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UsersFilteringParams, UsersResponse } from '@apis/usersApi.types';
import { UsersState } from '@redux/types/common/users/usersTypes';

const storeName = 'users';

const initialState: UsersState = {
  usersLoading: false,
  error: null,
  users: [],
  pagination: undefined,
};

const usersSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setUsersLoading(state: UsersState, action: PayloadAction<boolean>) {
      state.usersLoading = action.payload;
    },
    getUserSuccess(state: UsersState, action: PayloadAction<UsersResponse>) {
      state.users = action.payload.data;
      state.pagination = action.payload.meta.pagination;
      state.usersLoading = false;
    },
    getUsersFailure(state: UsersState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.usersLoading = false;
      state.users = [];
    },
    clearUsersList(state: UsersState) {
      state.usersLoading = false;
      state.error = null;
      state.users = [];
    },
  },
});

export const usersActions = {
  setUsersLoading: usersSlice.actions.setUsersLoading,
  getUserSuccess: usersSlice.actions.getUserSuccess,
  getUsersFailure: usersSlice.actions.getUsersFailure,
  clearUsersList: usersSlice.actions.clearUsersList,
  getUsersRequest: createAction<UsersFilteringParams>(`${storeName}/getUsersRequest`),
};

export const usersReducer = usersSlice.reducer;
