import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewCampaignsSaga,
  deleteSavedViewCampaignsSaga,
  editSavedViewCampaignsSaga,
  getCampaignsSavedViewById,
  getCampaignsSavedViewsSaga,
  pinSavedViewCampaignSaga,
  unpinSavedViewCampaignSaga,
  updatePinnedCampaignsSavedViewsSaga,
} from '@redux/sagas/saved_view/campaigns/campaignsSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewCampaigns';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewsCampaignsActions = generateActions(storeName, slice);

export const campaignsReducer = slice.reducer;

export const savedViewsCampaignsSagas: [ActionPattern, any][] = [
  [savedViewsCampaignsActions.getListRequest.type, getCampaignsSavedViewsSaga],
  [savedViewsCampaignsActions.getUnpinnedViewRequest.type, getCampaignsSavedViewById],
  [savedViewsCampaignsActions.updateSavedViewsRequest.type, updatePinnedCampaignsSavedViewsSaga],
  [savedViewsCampaignsActions.deleteViewRequest.type, deleteSavedViewCampaignsSaga],
  [savedViewsCampaignsActions.editViewRequest.type, editSavedViewCampaignsSaga],
  [savedViewsCampaignsActions.createViewRequest.type, createSavedViewCampaignsSaga],
  [savedViewsCampaignsActions.pinViewRequest.type, pinSavedViewCampaignSaga],
  [savedViewsCampaignsActions.unpinViewRequest.type, unpinSavedViewCampaignSaga],
];
