import { combineReducers } from 'redux';

import { addressLookupReducer } from '@redux/reducers/common/address-lookup/addressLookupReducer';
import { areaCountriesReducer } from '@redux/reducers/common/area-countries/areaCountriestReducer';
import { areaRegionsReducer } from '@redux/reducers/common/area-regions/areaRegionsReducer';
import { areaTeamsReducer } from '@redux/reducers/common/area-teams/areaTeamsReducer';
import { companiesReducer } from '@redux/reducers/common/companies/companiesReducer';
import { companiesDnbReducer } from '@redux/reducers/common/companies-dnb/companiesDnbReducer';
import { contactsReducer } from '@redux/reducers/common/contacts/contactsReducer';
import { disposalReducer } from '@redux/reducers/common/disposals/disposalsReducer';
import { mapboxReducer } from '@redux/reducers/common/mapbox-locations/mapboxLocationsReducer';
import { marketingListReducer } from '@redux/reducers/common/marketing-list/marketingListReducer';
import { membersReducer } from '@redux/reducers/common/members/membersReducer';
import { requirementsReducer } from '@redux/reducers/common/requirements/requirementsReducer';
import { segmentsReducer } from '@redux/reducers/common/segments/segmentsReducer';
import { societyReducer } from '@redux/reducers/common/society/societyReducer';
import { societySegmentsReducer } from '@redux/reducers/common/society-segments/societySegmentsReducer';
import { usersReducer } from '@redux/reducers/common/users/usersReducer';

const commonReducer = combineReducers({
  addressLookup: addressLookupReducer,
  areaCountries: areaCountriesReducer,
  areaRegions: areaRegionsReducer,
  areaTeams: areaTeamsReducer,
  companies: companiesReducer,
  companiesDnb: companiesDnbReducer,
  contacts: contactsReducer,
  disposals: disposalReducer,
  mapboxLocations: mapboxReducer,
  marketing: marketingListReducer,
  members: membersReducer,
  requirements: requirementsReducer,
  segments: segmentsReducer,
  society: societyReducer,
  societySegments: societySegmentsReducer,
  users: usersReducer,
});

export default commonReducer;
