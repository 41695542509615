import { combineReducers } from 'redux';

import { campaignsReducer } from './campaigns/campaignsReducer';
import { companiesReducer } from './companies/companiesReducer';
import { contactsReducer } from './contacts/contactsReducer';
import { disposalsReducer } from './disposals/disposalsReducer';
import { savedViewDisposalsInsightsReducer } from './insights/savedViewDisposalsInsightsReducer';
import { savedViewLettingInsightsReducer } from './insights/savedViewLettingInsightsReducer';
import { savedViewsRequirementInsightsReducer } from './insights/savedViewRequirementInsightsReducer';
import { savedViewSaleInsightsReducer } from './insights/savedViewSaleInsightsReducer';
import { savedViewViewingsInsightsReducer } from './insights/savedViewViewingsInsightsReducer';
import { lettingsReducer } from './lettings/lettingsReducer';
import { salesReducer } from './sales/salesReducer';
import { savedViewSocietyAcquisitionInsightsReducer } from './society-insights/savedViewSocietyAcquisitionInsightsReducer';
import { savedViewSocietyDisposalInsightsReducer } from './society-insights/savedViewSocietyDisposalInsightsReducer';

const savedViewsReducer = combineReducers({
  lettings: lettingsReducer,
  sales: salesReducer,

  societyAcquisitionInsights: savedViewSocietyAcquisitionInsightsReducer,
  societyDisposalInsights: savedViewSocietyDisposalInsightsReducer,
  viewingsInsights: savedViewViewingsInsightsReducer,
  lettingInsights: savedViewLettingInsightsReducer,
  saleInsights: savedViewSaleInsightsReducer,
  insightsRequirements: savedViewsRequirementInsightsReducer,
  disposalsInsights: savedViewDisposalsInsightsReducer,
  disposals: disposalsReducer,
  contacts: contactsReducer,
  companies: companiesReducer,
  campaigns: campaignsReducer,
});

export default savedViewsReducer;
