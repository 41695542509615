import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetSegmentsSuccessPayload, SegmentsState } from '@redux/types/common/segments/segmentsTypes';

const storeName = 'segments';

const initialState: SegmentsState = {
  loading: false,
  requestSuccessful: false,
  error: null,
  countries: [],
  regions: [],
  team: [],
  society: [],
};

const segmentsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setLoading(state: SegmentsState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getSegmentsSuccess(state: SegmentsState, action: PayloadAction<GetSegmentsSuccessPayload>) {
      state.countries = action.payload.countries;
      state.regions = action.payload.regions;
      state.team = action.payload.team;
      state.society = action.payload.society;
      state.loading = false;
      state.requestSuccessful = true;
    },
    getSegmentsFailure(state: SegmentsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.countries = [];
      state.regions = [];
      state.team = [];
      state.society = [];
    },
  },
});

export const segmentsActions = {
  setLoading: segmentsSlice.actions.setLoading,
  getSegmentsSuccess: segmentsSlice.actions.getSegmentsSuccess,
  getSegmentsFailure: segmentsSlice.actions.getSegmentsFailure,
  getSegmentsRequest: createAction(`${storeName}/getSegmentsRequest`),
};

export const segmentsReducer = segmentsSlice.reducer;
