import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AddressLookupState,
  GetAddressDoctorFullAddressSuccessPayload,
  GetAddressDoctorRequestPayload,
  GetAddressDoctorSuccessPayload,
  GetCoordsFromAddressRequest,
  GetCoordsFromAddressSuccessPayload,
  GetFinalAddressDoctorRequest,
  GetFinalAddressRequest,
  GetLoqateIndexPayload,
  GetLoqateIndexSubPayload,
  IPreviewAddress,
  ISetFinalAutocompleteAddress,
} from '@redux/types/common/address-lookup/addressLookupTypes';

const storeName = 'lettings';

const initialState: AddressLookupState = {
  addressAutocomplete: {
    loading: false,
    previewAddresses: [],
    fullPreviewAddresses: [],
  },
  addressDoctorAutocomplete: {
    loading: false,
    previewAddresses: [],
  },
  coords: undefined,
  coordsError: undefined,
  coordsLoading: false,
};

const addressLookupSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setAddressLoading(state: AddressLookupState, action: PayloadAction<boolean>) {
      state.addressAutocomplete = {
        ...state.addressAutocomplete,
        loading: action.payload,
      };
    },
    setAutocompletePreviewAddress(state: AddressLookupState, action: PayloadAction<IPreviewAddress[]>) {
      state.addressAutocomplete.previewAddresses = action.payload;
      state.addressAutocomplete.fullPreviewAddresses = [];
      state.addressAutocomplete.loading = false;
    },
    setAutocompleteFullPreviewAddress(state: AddressLookupState, action: PayloadAction<IPreviewAddress[]>) {
      state.addressAutocomplete.previewAddresses = [];
      state.addressAutocomplete.fullPreviewAddresses = action.payload;
      state.addressAutocomplete.loading = false;
    },
    setAutocompletePreviewAddressFailure(state: AddressLookupState) {
      state.addressAutocomplete.previewAddresses = [];
      state.addressAutocomplete.loading = false;
    },
    setAutocompleteFinalAddress(
      state: AddressLookupState,
      action: PayloadAction<ISetFinalAutocompleteAddress | undefined>
    ) {
      state.addressAutocomplete.fullAddress = action.payload;
      state.addressAutocomplete.loading = false;
    },

    setAddressDoctorLoading(state: AddressLookupState, action: PayloadAction<boolean>) {
      state.addressDoctorAutocomplete = {
        ...state.addressDoctorAutocomplete,
        loading: action.payload,
      };
    },
    getAddressDoctorPreviewSuccess(state: AddressLookupState, action: PayloadAction<GetAddressDoctorSuccessPayload>) {
      state.addressDoctorAutocomplete = {
        ...state.addressDoctorAutocomplete,
        loading: false,
        previewAddresses: action.payload.items,
      };
    },
    getAddressDoctorPreviewFailure(state: AddressLookupState) {
      state.addressDoctorAutocomplete = {
        ...state.addressDoctorAutocomplete,
        loading: false,
        previewAddresses: [],
      };
    },
    clearAddressDoctorList(state: AddressLookupState) {
      state.addressDoctorAutocomplete = {
        ...state.addressDoctorAutocomplete,
        loading: false,
        previewAddresses: [],
      };
    },
    getAddressDoctorFullAddressSuccess(
      state: AddressLookupState,
      action: PayloadAction<GetAddressDoctorFullAddressSuccessPayload>
    ) {
      state.addressDoctorAutocomplete = {
        ...state.addressDoctorAutocomplete,
        loading: false,
        fullAddress: action.payload.address,
        fullAddressUPRN: Array.isArray(action.payload.enrichment?.UPRN)
          ? action.payload.enrichment?.UPRN[0]
          : action.payload.enrichment?.UPRN,
      };
    },

    setCoordsFromAddressError(state: AddressLookupState, action: PayloadAction<string | undefined>) {
      state.coordsError = action.payload;
    },
    setCoordsFromAddressLoading(state: AddressLookupState, action: PayloadAction<boolean>) {
      state.coordsLoading = action.payload;
    },
    getCoordsFromAddressSuccess(state: AddressLookupState, action: PayloadAction<GetCoordsFromAddressSuccessPayload>) {
      state.coords = action.payload ? { ...action.payload } : undefined;
      state.coordsLoading = false;
    },
  },
});

export const addressLookupActions = {
  setAutocompletePreviewAddress: addressLookupSlice.actions.setAutocompletePreviewAddress,
  setAutocompleteFullPreviewAddress: addressLookupSlice.actions.setAutocompleteFullPreviewAddress,
  setAutocompletePreviewAddressFailure: addressLookupSlice.actions.setAutocompletePreviewAddressFailure,
  setAutocompleteFinalAddress: addressLookupSlice.actions.setAutocompleteFinalAddress,
  setAddressLoading: addressLookupSlice.actions.setAddressLoading,
  getPreviewAddressAutocomplete: createAction<GetLoqateIndexPayload>(`${storeName}/getPreviewAddressAutocomplete`),
  getAutocompleteFullAddress: createAction<GetLoqateIndexSubPayload>(`${storeName}/getAutocompleteFullAddress`),
  getAutocompleteFinalAddress: createAction<GetFinalAddressRequest['payload']>(
    `${storeName}/getAutocompleteFinalAddress`
  ),

  setAddressDoctorLoading: addressLookupSlice.actions.setAddressDoctorLoading,
  getAddressDoctorPreviewSuccess: addressLookupSlice.actions.getAddressDoctorPreviewSuccess,
  getAddressDoctorPreviewFailure: addressLookupSlice.actions.getAddressDoctorPreviewFailure,
  getAddressDoctorPreviewRequest: createAction<GetAddressDoctorRequestPayload>(
    `${storeName}/getAddressDoctorPreviewRequest`
  ),
  clearAddressDoctorList: addressLookupSlice.actions.clearAddressDoctorList,
  getAddressDoctorFullAddressSuccess: addressLookupSlice.actions.getAddressDoctorFullAddressSuccess,
  getAddressDoctorFullAddressRequest: createAction<GetFinalAddressDoctorRequest>(
    `${storeName}/getAddressDoctorFullAddressRequest`
  ),

  setCoordsFromAddressError: addressLookupSlice.actions.setCoordsFromAddressError,
  setCoordsFromAddressLoading: addressLookupSlice.actions.setCoordsFromAddressLoading,
  getCoordsFromAddressSuccess: addressLookupSlice.actions.getCoordsFromAddressSuccess,
  getCoordsFromAddressRequest: createAction<GetCoordsFromAddressRequest>(`${storeName}/getCoordsFromAddressRequest`),
};

export const addressLookupReducer = addressLookupSlice.reducer;
