import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateNewCompanyRequestPayload,
  DeleteCrmCompanyPayload,
  ManageCompanyState,
} from '@redux/types/crm/companies/companiesTypes';
import { Company } from '@shared/models/company/company';

const storeName = 'companiesManage';

const initialState: ManageCompanyState = {
  createLoading: false,
  deletingCompanyLoading: false,
};

const companiesManageSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setCreateLoading(state: ManageCompanyState, action: PayloadAction<boolean>) {
      state.createLoading = action.payload;
      state.error = undefined;
    },
    createCompanySuccess(state: ManageCompanyState, action: PayloadAction<Company>) {
      state.companyData = action.payload;
      state.createLoading = false;
    },
    createCompanyFailure(state: ManageCompanyState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.createLoading = false;
    },
    setDeleteCompanyLoading(state: ManageCompanyState, action: PayloadAction<boolean>) {
      state.deletingCompanyLoading = action.payload;
    },
    deleteCompanySuccess(state: ManageCompanyState) {
      state.deletingCompanyLoading = false;
    },
    deleteCompanyFailure(state: ManageCompanyState) {
      state.deletingCompanyLoading = false;
    },
  },
});

export const companyManageActions = {
  setCreateLoading: companiesManageSlice.actions.setCreateLoading,
  createCompanySuccess: companiesManageSlice.actions.createCompanySuccess,
  createCompanyFailure: companiesManageSlice.actions.createCompanyFailure,
  createCompanyRequest: createAction<CreateNewCompanyRequestPayload>(`${storeName}/createCompanyRequest`),

  setDeleteCompanyLoading: companiesManageSlice.actions.setDeleteCompanyLoading,
  deleteCompanySuccess: companiesManageSlice.actions.deleteCompanySuccess,
  deleteCompanyFailure: companiesManageSlice.actions.deleteCompanyFailure,
  deleteCompanyRequest: createAction<DeleteCrmCompanyPayload['payload']>(`${storeName}/deleteContactRequest`),
};

export const companiesManageReducer = companiesManageSlice.reducer;
