import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewDisposalsSaga,
  deleteSavedViewDisposalsSaga,
  editSavedViewDisposalsSaga,
  getDisposalsSavedViewById,
  getDisposalsSavedViewsSaga,
  pinSavedViewDisposalSaga,
  unpinSavedViewDisposalSaga,
  updatePinnedDisposalsSavedViewsSaga,
} from '@redux/sagas/saved_view/disposals/disposalsSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewDisposals';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewsDisposalsActions = generateActions(storeName, slice);

export const disposalsReducer = slice.reducer;

export const savedViewsDisposalsSagas: [ActionPattern, any][] = [
  [savedViewsDisposalsActions.getListRequest.type, getDisposalsSavedViewsSaga],
  [savedViewsDisposalsActions.getUnpinnedViewRequest.type, getDisposalsSavedViewById],
  [savedViewsDisposalsActions.updateSavedViewsRequest.type, updatePinnedDisposalsSavedViewsSaga],
  [savedViewsDisposalsActions.deleteViewRequest.type, deleteSavedViewDisposalsSaga],
  [savedViewsDisposalsActions.editViewRequest.type, editSavedViewDisposalsSaga],
  [savedViewsDisposalsActions.createViewRequest.type, createSavedViewDisposalsSaga],
  [savedViewsDisposalsActions.pinViewRequest.type, pinSavedViewDisposalSaga],
  [savedViewsDisposalsActions.unpinViewRequest.type, unpinSavedViewDisposalSaga],
];
