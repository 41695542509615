import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewContactsSaga,
  deleteSavedViewContactsSaga,
  editSavedViewContactsSaga,
  getContactsSavedViewById,
  getContactsSavedViewsSaga,
  pinSavedViewContactSaga,
  unpinSavedViewContactSaga,
  updatePinnedContactsSavedViewsSaga,
} from '@redux/sagas/saved_view/contacts/contactsSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewContacts';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewsContactsActions = generateActions(storeName, slice);

export const contactsReducer = slice.reducer;

export const savedViewsContactsSagas: [ActionPattern, any][] = [
  [savedViewsContactsActions.getListRequest.type, getContactsSavedViewsSaga],
  [savedViewsContactsActions.getUnpinnedViewRequest.type, getContactsSavedViewById],
  [savedViewsContactsActions.updateSavedViewsRequest.type, updatePinnedContactsSavedViewsSaga],
  [savedViewsContactsActions.deleteViewRequest.type, deleteSavedViewContactsSaga],
  [savedViewsContactsActions.editViewRequest.type, editSavedViewContactsSaga],
  [savedViewsContactsActions.createViewRequest.type, createSavedViewContactsSaga],
  [savedViewsContactsActions.pinViewRequest.type, pinSavedViewContactSaga],
  [savedViewsContactsActions.unpinViewRequest.type, unpinSavedViewContactSaga],
];
