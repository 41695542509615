import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetLettingInsightsRequestPayload,
  GetLettingInsightsSuccess,
  LettingInsightsState,
} from '@redux/types/insights/letting/lettingInsightsTypes';

const storeName = 'lettingInsights';

const initialState: LettingInsightsState = {
  error: null,
  loading: false,
  reports: [],
  societiesInvalid: false,
};

const lettingInsightsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setListLoading(state: LettingInsightsState, action: PayloadAction<boolean>) {
      state.error = null;
      state.loading = action.payload;
      state.reports = [];
      state.societiesInvalid = false;
      state.meta = undefined;
    },
    setListSocietiesInvalid(state: LettingInsightsState, action: PayloadAction<boolean>) {
      state.societiesInvalid = action.payload;
    },
    getListSuccess(state: LettingInsightsState, action: PayloadAction<GetLettingInsightsSuccess>) {
      state.loading = false;
      state.reports = action.payload.data;
      state.meta = action.payload.meta;
    },
    getListFailure(state: LettingInsightsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const lettingInsightsActions = {
  setListLoading: lettingInsightsSlice.actions.setListLoading,
  setListSocietiesInvalid: lettingInsightsSlice.actions.setListSocietiesInvalid,
  getListSuccess: lettingInsightsSlice.actions.getListSuccess,
  getListFailure: lettingInsightsSlice.actions.getListFailure,
  getListRequest: createAction<GetLettingInsightsRequestPayload>(`${storeName}/getListRequest`),
};

export const lettingInsightsReducer = lettingInsightsSlice.reducer;
