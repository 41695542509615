import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequirementManageState } from '@redux/types/requirements/requirementsTypes';

const storeName = 'requirementManage';

const initialState: RequirementManageState = {
  loading: false,
};

const requirementManageSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setRequirementLoading(state: RequirementManageState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getRequirementSuccess(state: RequirementManageState, action: PayloadAction<any>) {
      state.manage = action.payload;
      state.loading = false;
    },
    getRequirementFailure(state: RequirementManageState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
      state.manage = undefined;
    },
  },
});

export const requirementManageActions = {
  setRequirementLoading: requirementManageSlice.actions.setRequirementLoading,
  getRequirementSuccess: requirementManageSlice.actions.getRequirementSuccess,
  getRequirementFailure: requirementManageSlice.actions.getRequirementFailure,
  getRequirementRequest: createAction<Id>(`${storeName}/getRequirementRequest`),
};

export const requirementManageReducer = requirementManageSlice.reducer;
