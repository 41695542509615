import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewSocietyAcquisitionInsightsSaga,
  deleteSavedViewSocietyAcquisitionInsightsSaga,
  editSavedViewSocietyAcquisitionInsightsSaga,
  getSocietyAcquisitionInsightsByIdSaga,
  getSocietyAcquisitionInsightsSaga,
  pinSocietyAcquisitionInsightsSavedViewsSaga,
  unpinSocietyAcquisitionInsightsSavedViewsSaga,
  updatePinnedSocietyAcquisitionInsightsSavedViewsSaga,
} from '@redux/sagas/saved_view/society-insights/savedViewSocietyAcquisitionInsightsSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewSocietyAcquisitionInsights';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewSocietyAcquisitionInsightsActions = generateActions(storeName, slice);

export const savedViewSocietyAcquisitionInsightsReducer = slice.reducer;

export const savedViewSocietyAcquisitionInsightsSagas: [ActionPattern, any][] = [
  [savedViewSocietyAcquisitionInsightsActions.getListRequest.type, getSocietyAcquisitionInsightsSaga],
  [savedViewSocietyAcquisitionInsightsActions.getUnpinnedViewRequest.type, getSocietyAcquisitionInsightsByIdSaga],
  [
    savedViewSocietyAcquisitionInsightsActions.updateSavedViewsRequest.type,
    updatePinnedSocietyAcquisitionInsightsSavedViewsSaga,
  ],
  [savedViewSocietyAcquisitionInsightsActions.deleteViewRequest.type, deleteSavedViewSocietyAcquisitionInsightsSaga],
  [savedViewSocietyAcquisitionInsightsActions.editViewRequest.type, editSavedViewSocietyAcquisitionInsightsSaga],
  [savedViewSocietyAcquisitionInsightsActions.createViewRequest.type, createSavedViewSocietyAcquisitionInsightsSaga],
  [savedViewSocietyAcquisitionInsightsActions.pinViewRequest.type, pinSocietyAcquisitionInsightsSavedViewsSaga],
  [savedViewSocietyAcquisitionInsightsActions.unpinViewRequest.type, unpinSocietyAcquisitionInsightsSavedViewsSaga],
];
