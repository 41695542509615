import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DisposalsInsightsState,
  GetDisposalsInsightsRequestPayload,
  GetDisposalsInsightsSuccess,
} from '@redux/types/insights/disposals-insights/disposalsInsightsTypes';

const storeName = 'disposalsInsights';

const initialState: DisposalsInsightsState = {
  error: null,
  loading: false,
  reports: [],
  societiesInvalid: false,
};

const disposalsInsightsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setListLoading(state: DisposalsInsightsState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setListSocietiesInvalid(state: DisposalsInsightsState, action: PayloadAction<boolean>) {
      state.societiesInvalid = action.payload;
    },
    getListSuccess(state: DisposalsInsightsState, action: PayloadAction<GetDisposalsInsightsSuccess>) {
      state.loading = false;
      state.reports = action.payload.data;
      state.meta = action.payload.meta;
    },
    getListFailure(state: DisposalsInsightsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const disposalsInsightsActions = {
  setListLoading: disposalsInsightsSlice.actions.setListLoading,
  setListSocietiesInvalid: disposalsInsightsSlice.actions.setListSocietiesInvalid,
  getListSuccess: disposalsInsightsSlice.actions.getListSuccess,
  getListFailure: disposalsInsightsSlice.actions.getListFailure,
  getListRequest: createAction<GetDisposalsInsightsRequestPayload>(`${storeName}/getListRequest`),
};

export const disposalsInsightsReducer = disposalsInsightsSlice.reducer;
