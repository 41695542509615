import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DisposalsState,
  GetDisposalsRequestPayload,
  GetDisposalsSuccessPayload,
} from '@redux/types/common/disposals/disposalTypes';

const storeName = 'disposals';

const initialState: DisposalsState = {
  disposalsLoading: false,
  disposals: [],
  pagination: undefined,
};

const disposalsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setDisposalsLoading(state: DisposalsState, action: PayloadAction<boolean>) {
      state.disposalsLoading = action.payload;
      state.disposals = [];
      state.pagination = undefined;
    },
    getDisposalsSuccess(state: DisposalsState, action: PayloadAction<GetDisposalsSuccessPayload>) {
      state.disposals = action.payload.data;
      state.pagination = action.payload.meta.pagination;
      state.disposalsLoading = false;
    },
    getDisposalsFailure(state: DisposalsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.disposalsLoading = false;
      state.disposals = [];
    },
    clearDisposalsList(state: DisposalsState) {
      state.disposals = [];
      state.pagination = undefined;
      state.error = undefined;
    },
  },
});

export const disposalsActions = {
  setDisposalsLoading: disposalsSlice.actions.setDisposalsLoading,
  getDisposalsSuccess: disposalsSlice.actions.getDisposalsSuccess,
  getDisposalsFailure: disposalsSlice.actions.getDisposalsFailure,
  clearDisposalsList: disposalsSlice.actions.clearDisposalsList,
  getDisposalsRequest: createAction<GetDisposalsRequestPayload>(`${storeName}/getDisposalsRequest`),
};

export const disposalReducer = disposalsSlice.reducer;
