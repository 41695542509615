import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetSocietyInsightsFilteringRequestPayload } from '@redux/types/insights/insightsTypes';
import {
  GetSocietyDisposalInsightsSuccess,
  SocietyDisposalInsightsState,
} from '@redux/types/insights/society-disposal/societyDisposalInsightsTypes';

const storeName = 'societyDisposals';

const initialState: SocietyDisposalInsightsState = {
  error: null,
  loading: false,
  reports: [],
  segments: [],
  societiesInvalid: false,
};

const societyDisposalInsightsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setListLoading(state: SocietyDisposalInsightsState, action: PayloadAction<boolean>) {
      state.error = null;
      state.loading = action.payload;
      state.reports = [];
      state.segments = [];
    },
    setListSocietiesInvalid(state: SocietyDisposalInsightsState, action: PayloadAction<boolean>) {
      state.societiesInvalid = action.payload;
    },
    getListSuccess(state: SocietyDisposalInsightsState, action: PayloadAction<GetSocietyDisposalInsightsSuccess>) {
      state.loading = false;
      state.reports = action.payload.data;
      state.segments = action.payload.meta?.segments || [];
    },
    getListFailure(state: SocietyDisposalInsightsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const societyDisposalsActions = {
  setListLoading: societyDisposalInsightsSlice.actions.setListLoading,
  setListSocietiesInvalid: societyDisposalInsightsSlice.actions.setListSocietiesInvalid,
  getListSuccess: societyDisposalInsightsSlice.actions.getListSuccess,
  getListFailure: societyDisposalInsightsSlice.actions.getListFailure,
  getListRequest: createAction<GetSocietyInsightsFilteringRequestPayload>(`${storeName}/getListRequest`),
};

export const societyDisposalInsightsReducer = societyDisposalInsightsSlice.reducer;
