import { combineReducers } from 'redux';

import { campaignsReducer } from './campaigns-list/campaignsListReducer';
import { manageMultiPropertyCampaignReducer } from './manage-multi-property-campaign/manageMultiPropertyCampaignReducer';
import { manageSinglePropertyCampaignReducer } from './manage-single-property-campaign/manageSinglePropertyCampaignReducer';
import { campaignRecipientsReducer } from './recipients/campaignRecipientsReducer';
import { campaignResultsReducer } from './results/campaignResultsReducer';

const CampaignsReducer = combineReducers({
  list: campaignsReducer,
  manageSinglePropertyCampaign: manageSinglePropertyCampaignReducer,
  manageMultiPropertyCampaign: manageMultiPropertyCampaignReducer,
  recipients: campaignRecipientsReducer,
  results: campaignResultsReducer,
});

export default CampaignsReducer;
