import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewLettingSaga,
  deleteSavedViewLettingSaga,
  editSavedViewLettingSaga,
  getLettingSaga,
  getLettingsSaga,
  pinLettingsSavedViewsSaga,
  unpinLettingsSavedViewsSaga,
  updatePinnedLettingsSavedViewsSaga,
} from '@redux/sagas/saved_view/lettings/lettingsSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewLettings';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewsLettingsActions = generateActions(storeName, slice);

export const lettingsReducer = slice.reducer;

export const savedViewsLettingsSagas: [ActionPattern, any][] = [
  [savedViewsLettingsActions.getListRequest.type, getLettingsSaga],
  [savedViewsLettingsActions.getUnpinnedViewRequest.type, getLettingSaga],
  [savedViewsLettingsActions.updateSavedViewsRequest.type, updatePinnedLettingsSavedViewsSaga],
  [savedViewsLettingsActions.deleteViewRequest.type, deleteSavedViewLettingSaga],
  [savedViewsLettingsActions.editViewRequest.type, editSavedViewLettingSaga],
  [savedViewsLettingsActions.createViewRequest.type, createSavedViewLettingSaga],
  [savedViewsLettingsActions.pinViewRequest.type, pinLettingsSavedViewsSaga],
  [savedViewsLettingsActions.unpinViewRequest.type, unpinLettingsSavedViewsSaga],
];
