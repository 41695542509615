import { combineReducers } from 'redux';

import { updatePasswordReducer } from './password/updatePasswordReducer';
import { profileReducer } from './profile/profileReducer';
import { templatesReducer } from './templates/templatesReducer';
import { twitterConnectionReducer } from './twitter-connection/twitterConnectionReducer';

const UserReducer = combineReducers({
  updatePassword: updatePasswordReducer,
  profile: profileReducer,
  templates: templatesReducer,
  twitterConnection: twitterConnectionReducer,
});

export default UserReducer;
