import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetMembersRequestPayload,
  GetMembersSuccessPayload,
  MembersState,
} from '@redux/types/common/members/membersTypes';

const storeName = 'members';

const initialState: MembersState = {
  loading: false,
  error: null,
  members: [],
  pagination: undefined,
};

const membersSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setListLoading(state: MembersState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getListSuccess(state: MembersState, action: PayloadAction<GetMembersSuccessPayload>) {
      state.members = action.payload.data;
      state.pagination = action.payload.meta.pagination;
      state.loading = false;
    },
    getListFailure(state: MembersState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.members = [];
    },
    clearList(state: MembersState) {
      state.loading = false;
      state.error = null;
      state.members = [];
    },
  },
});

export const membersActions = {
  setListLoading: membersSlice.actions.setListLoading,
  getListSuccess: membersSlice.actions.getListSuccess,
  getListFailure: membersSlice.actions.getListFailure,
  clearList: membersSlice.actions.clearList,
  getListRequest: createAction<GetMembersRequestPayload>(`${storeName}/getListRequest`),
};

export const membersReducer = membersSlice.reducer;
