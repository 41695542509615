import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateEmailTemplateRequestPayload,
  EditEmailTemplateRequestPayload,
  EmailTemplate,
  GetTemplatesRequestPayload,
  GetTemplatesSuccessPayload,
  SendTestEmailPayload,
  TemplatesState,
} from '@redux/types/user/templates/templatesTypes';

const storeName = 'emailTemplates';

const initialState: TemplatesState = {
  list: [],
  loading: false,
  detailsLoading: false,
  createEditLoading: false,
  sendTestLoading: false,
  sendTestSuccess: false,
};

const templatesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setTemplatesLoading(state: TemplatesState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.list = [];
    },
    getTemplatesSuccess(state: TemplatesState, action: PayloadAction<GetTemplatesSuccessPayload>) {
      state.list = action.payload.data;
      state.loading = false;
      state.pagination = action.payload.meta.pagination;
    },
    getTemplatesFailure(state: TemplatesState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
    setTemplateDetailsLoading(state: TemplatesState, action: PayloadAction<boolean>) {
      state.detailsLoading = action.payload;
      state.details = undefined;
      state.detailsError = undefined;
    },
    getTemplateDetailsSuccess(state: TemplatesState, action: PayloadAction<EmailTemplate>) {
      state.details = action.payload;
      state.detailsLoading = false;
    },
    getTemplateDetailsFailure(state: TemplatesState, action: PayloadAction<string>) {
      state.details = undefined;
      state.detailsLoading = false;
      state.detailsError = action.payload;
    },
    setCreateEditTemplateLoading(state: TemplatesState, action: PayloadAction<boolean>) {
      state.createEditLoading = action.payload;
    },
    editTemplateSuccess(state: TemplatesState, action: PayloadAction<EmailTemplate>) {
      state.details = action.payload;
      state.createEditLoading = false;
      state.list = state.list.map((template) => {
        if (template.id == action.payload.id) {
          return action.payload;
        }

        return template;
      });
    },
    editTemplateFailure(state: TemplatesState, action: PayloadAction<TemplatesState['createEditError']>) {
      state.createEditError = action.payload;
      state.createEditLoading = false;
    },
    createTemplateSuccess(state: TemplatesState, action: PayloadAction<EmailTemplate>) {
      state.details = action.payload;
      state.createEditLoading = false;
      state.list = [...state.list, action.payload];
    },
    createTemplateFailure(state: TemplatesState, action: PayloadAction<TemplatesState['createEditError']>) {
      state.createEditError = action.payload;
      state.createEditLoading = false;
    },
    clearTemplate(state: TemplatesState) {
      state.details = undefined;
    },
    setSendTestLoading(state: TemplatesState, action: PayloadAction<boolean>) {
      state.sendTestLoading = action.payload;
      state.sendTestSuccess = false;
    },
    sendTestSuccess(state: TemplatesState, action: PayloadAction<boolean>) {
      state.sendTestLoading = false;
      state.sendTestSuccess = action.payload;
    },
    sendTestFailure(state: TemplatesState, action: PayloadAction<string>) {
      state.sendTestLoading = false;
      state.sendTestSuccess = false;
      state.sendTestError = action.payload;
    },
    clearSenTestSuccess(state: TemplatesState) {
      state.sendTestSuccess = false;
    },
  },
});

export const templatesActions = {
  setTemplatesLoading: templatesSlice.actions.setTemplatesLoading,
  getTemplatesSuccess: templatesSlice.actions.getTemplatesSuccess,
  getTemplatesFailure: templatesSlice.actions.getTemplatesFailure,
  getTemplatesRequest: createAction<GetTemplatesRequestPayload>(`${storeName}/getTemplates`),
  setTemplateDetailsLoading: templatesSlice.actions.setTemplateDetailsLoading,
  getTemplateDetailsSuccess: templatesSlice.actions.getTemplateDetailsSuccess,
  getTemplateDetailsFailure: templatesSlice.actions.getTemplateDetailsFailure,
  getTemplateDetailsRequest: createAction<Id>(`${storeName}/getTemplateDetailsRequest`),
  setCreateEditTemplateLoading: templatesSlice.actions.setCreateEditTemplateLoading,
  editTemplateSuccess: templatesSlice.actions.editTemplateSuccess,
  editTemplateFailure: templatesSlice.actions.editTemplateFailure,
  editTemplateRequest: createAction<EditEmailTemplateRequestPayload>(`${storeName}/editTemplateRequest`),
  createTemplateSuccess: templatesSlice.actions.createTemplateSuccess,
  createTemplateFailure: templatesSlice.actions.createTemplateFailure,
  createTemplateRequest: createAction<CreateEmailTemplateRequestPayload>(`${storeName}/createTemplateRequest`),
  clearTemplate: templatesSlice.actions.clearTemplate,
  setSendTestLoading: templatesSlice.actions.setSendTestLoading,
  sendTestSuccess: templatesSlice.actions.sendTestSuccess,
  sendTestFailure: templatesSlice.actions.sendTestFailure,
  sendTestRequest: createAction<SendTestEmailPayload>(`${storeName}/sendTestRequest`),
  clearSenTestSuccess: templatesSlice.actions.clearSenTestSuccess,
};

export const templatesReducer = templatesSlice.reducer;
