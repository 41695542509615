import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewViewingsInsightsSaga,
  deleteSavedViewViewingsInsightsSaga,
  editSavedViewViewingsInsightsSaga,
  getViewingsInsightsByIdSaga,
  getViewingsInsightsSaga,
  pinViewingsInsightsSavedViewsSaga,
  unpinViewingsInsightsSavedViewsSaga,
  updatePinnedViewingsInsightsSavedViewsSaga,
} from '@redux/sagas/saved_view/insights/savedViewViewingsInsightsSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewViewingsInsights';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewViewingsInsightsActions = generateActions(storeName, slice);

export const savedViewViewingsInsightsReducer = slice.reducer;

export const savedViewViewingsInsightsSagas: [ActionPattern, any][] = [
  [savedViewViewingsInsightsActions.getListRequest.type, getViewingsInsightsSaga],
  [savedViewViewingsInsightsActions.getUnpinnedViewRequest.type, getViewingsInsightsByIdSaga],
  [savedViewViewingsInsightsActions.updateSavedViewsRequest.type, updatePinnedViewingsInsightsSavedViewsSaga],
  [savedViewViewingsInsightsActions.deleteViewRequest.type, deleteSavedViewViewingsInsightsSaga],
  [savedViewViewingsInsightsActions.editViewRequest.type, editSavedViewViewingsInsightsSaga],
  [savedViewViewingsInsightsActions.createViewRequest.type, createSavedViewViewingsInsightsSaga],
  [savedViewViewingsInsightsActions.pinViewRequest.type, pinViewingsInsightsSavedViewsSaga],
  [savedViewViewingsInsightsActions.unpinViewRequest.type, unpinViewingsInsightsSavedViewsSaga],
];
