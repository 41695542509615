import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetMapboxLocationsRequestPayload,
  IMapboxLocation,
  MapboxLocationsState,
} from '@redux/types/common/mapbox-locations/mapboxLocationsTypes';

const storeName = 'mapbox';

const initialState: MapboxLocationsState = {
  list: [],
  loading: false,
  error: null,
};

const mapboxSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setMapboxLoading(state: MapboxLocationsState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getMapboxLocationsSuccess(state: MapboxLocationsState, action: PayloadAction<IMapboxLocation[]>) {
      state.list = action.payload;
      state.loading = false;
    },
    getMapboxLocationsFailure(state: MapboxLocationsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.list = [];
    },
    clearMapboxLocations(state: MapboxLocationsState) {
      state = initialState;
    },
  },
});

export const mapboxActions = {
  setMapboxLoading: mapboxSlice.actions.setMapboxLoading,
  getMapboxLocationsSuccess: mapboxSlice.actions.getMapboxLocationsSuccess,
  getMapboxLocationsFailure: mapboxSlice.actions.getMapboxLocationsFailure,
  clearMapboxLocations: mapboxSlice.actions.clearMapboxLocations,
  getMapboxLocationRequest: createAction<GetMapboxLocationsRequestPayload>(`${storeName}/getMapboxLocationRequest`),
};

export const mapboxReducer = mapboxSlice.reducer;
