import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TwitterConnectionState } from '@redux/types/user/twitter-connection/twitterConnectionTypes';

const storeName = 'twitter-connection';

const initialState: TwitterConnectionState = {
  loading: false,
  error: null,
};

const twitterConnectionSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setTwitterLoading(state: TwitterConnectionState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
    },
    connectTwitterFailure(state: TwitterConnectionState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    disconnectTwitterSuccess(state: TwitterConnectionState) {
      state.loading = false;
      state.error = null;
    },
    disconnectTwitterFailure(state: TwitterConnectionState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const twitterConnectionActions = {
  setTwitterLoading: twitterConnectionSlice.actions.setTwitterLoading,

  connectTwitterFailure: twitterConnectionSlice.actions.connectTwitterFailure,
  connectTwitterRequest: createAction(`${storeName}/connectTwitter`),

  disconnectTwitterSuccess: twitterConnectionSlice.actions.disconnectTwitterSuccess,
  disconnectTwitterFailure: twitterConnectionSlice.actions.disconnectTwitterFailure,
  disconnectTwitterRequest: createAction(`${storeName}/disconnectTwitter`),
};

export const twitterConnectionReducer = twitterConnectionSlice.reducer;
