import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AreaRegionsState } from '@redux/types/common/area-regions/areaRegionsTypes';

const storeName = 'areaRegions';

const initialState: AreaRegionsState = {
  loading: false,
  error: null,
  regions: [],
};

const areaRegionsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setAreaRegionsLoading(state: AreaRegionsState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getAreRegionsSuccess(state: AreaRegionsState, action) {
      state.regions = action.payload;
      state.loading = false;
    },
    getAreaRegionsFailure(state: AreaRegionsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.regions = [];
    },
  },
});

export const areaRegionsActions = {
  getAreRegionsSuccess: areaRegionsSlice.actions.getAreRegionsSuccess,
  setAreaRegionsLoading: areaRegionsSlice.actions.setAreaRegionsLoading,
  getAreaRegionsFailure: areaRegionsSlice.actions.getAreaRegionsFailure,
  getAreaRegionsRequest: createAction(`${storeName}/getAreaRegionsRequest`),
};

export const areaRegionsReducer = areaRegionsSlice.reducer;
