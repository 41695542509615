import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UpdatePasswordRequest, UpdatePasswordState } from '@redux/types/user/password/updatePasswordTypes';

const storeName = 'password';

const initialState: UpdatePasswordState = {
  errors: [],
  loading: false,
  success: false,
};

const updatePasswordSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    handleRequest(state: UpdatePasswordState) {
      state.errors = [];
      state.loading = true;
      state.success = false;
    },
    handleSuccess(state: UpdatePasswordState) {
      state.loading = false;
      state.success = true;
    },
    handleFailure(state: UpdatePasswordState, action: PayloadAction<UpdatePasswordState['errors']>) {
      state.errors = action.payload;
      state.loading = false;
    },
  },
});

export const updatePasswordActions = {
  handleRequest: updatePasswordSlice.actions.handleRequest,
  handleSuccess: updatePasswordSlice.actions.handleSuccess,
  handleFailure: updatePasswordSlice.actions.handleFailure,
  submitRequest: createAction<UpdatePasswordRequest['payload']>(`${storeName}/submitRequest`),
};

export const updatePasswordReducer = updatePasswordSlice.reducer;
