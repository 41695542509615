import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewLettingInsightsSaga,
  deleteSavedViewLettingInsightsSaga,
  editSavedViewLettingInsightsSaga,
  getLettingInsightsByIdSaga,
  getLettingInsightsSaga,
  pinLettingInsightsSavedViewsSaga,
  unpinLettingInsightsSavedViewsSaga,
  updatePinnedLettingInsightsSavedViewsSaga,
} from '@redux/sagas/saved_view/insights/savedViewLettingInsightsSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewLettingInsights';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewLettingInsightsActions = generateActions(storeName, slice);

export const savedViewLettingInsightsReducer = slice.reducer;

export const savedViewLettingInsightsSagas: [ActionPattern, any][] = [
  [savedViewLettingInsightsActions.getListRequest.type, getLettingInsightsSaga],
  [savedViewLettingInsightsActions.getUnpinnedViewRequest.type, getLettingInsightsByIdSaga],
  [savedViewLettingInsightsActions.updateSavedViewsRequest.type, updatePinnedLettingInsightsSavedViewsSaga],
  [savedViewLettingInsightsActions.deleteViewRequest.type, deleteSavedViewLettingInsightsSaga],
  [savedViewLettingInsightsActions.editViewRequest.type, editSavedViewLettingInsightsSaga],
  [savedViewLettingInsightsActions.createViewRequest.type, createSavedViewLettingInsightsSaga],
  [savedViewLettingInsightsActions.pinViewRequest.type, pinLettingInsightsSavedViewsSaga],
  [savedViewLettingInsightsActions.unpinViewRequest.type, unpinLettingInsightsSavedViewsSaga],
];
