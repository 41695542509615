import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetRequirementInsightsRequestPayload,
  GetRequirementInsightsResponse,
  RequirementInsightsState,
} from '@redux/types/insights/requirement/requirementInsightsTypes';

const storeName = 'insightsRequirements';

const initialState: RequirementInsightsState = {
  error: null,
  loading: false,
  reports: [],
};

const insightsRequirementsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setStatisticLoading(state: RequirementInsightsState, action: PayloadAction<boolean>) {
      state.error = null;
      state.loading = action.payload;
      state.reports = [];
      state.meta = undefined;
    },
    getStatisticSuccess(state: RequirementInsightsState, action: PayloadAction<GetRequirementInsightsResponse>) {
      state.loading = false;
      state.reports = action.payload.data;
      state.meta = action.payload.meta;
    },
    getStatisticFailure(state: RequirementInsightsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const insightsRequirementsActions = {
  setStatisticLoading: insightsRequirementsSlice.actions.setStatisticLoading,
  getStatisticSuccess: insightsRequirementsSlice.actions.getStatisticSuccess,
  getStatisticFailure: insightsRequirementsSlice.actions.getStatisticFailure,
  getStatisticRequest: createAction<GetRequirementInsightsRequestPayload>(`${storeName}/getStatisticRequest`),
};

export const insightsRequirementsReducer = insightsRequirementsSlice.reducer;
