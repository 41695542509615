import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetSocietyAcquisitionsListRequestPayload,
  GetSocietyAcquisitionsListSuccessPayload,
  GetSocietyDisposalsRequestPayload,
  GetSocietyDisposalsSuccessPayload,
  GetSocietyMembersRequestPayload,
  GetSocietyMembersSuccessPayload,
  SocietyState,
} from '@redux/types/common/society/societyTypes';

const storeName = 'society';

const initialState: SocietyState = {
  societyMembersLoading: false,
  societyMembersError: null,
  societyMembersList: [],
  societyAcquisitionsLoading: false,
  societyAcquisitionsError: null,
  societyAcquisitionsList: [],
  societyDisposalsLoading: false,
  societyDisposalsError: null,
  societyDisposalsList: [],
};

const societySlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setSocietyMembersLoading(state: SocietyState, action: PayloadAction<boolean>) {
      state.societyMembersLoading = action.payload;
    },
    setSocietyAcquisitionsLoading(state: SocietyState, action: PayloadAction<boolean>) {
      state.societyAcquisitionsLoading = action.payload;
    },
    setSocietyDisposalsLoading(state: SocietyState, action: PayloadAction<boolean>) {
      state.societyDisposalsLoading = action.payload;
    },
    getSocietyMembersSuccess(state: SocietyState, action: PayloadAction<GetSocietyMembersSuccessPayload>) {
      state.societyMembersList = action.payload.data;
      state.societyMembersLoading = false;
    },
    getSocietyMembersFailure(state: SocietyState, action: PayloadAction<string>) {
      state.societyMembersError = action.payload;
      state.societyMembersLoading = false;
      state.societyMembersList = [];
    },
    clearSocietyMembersList(state: SocietyState) {
      state.societyMembersLoading = false;
      state.societyMembersError = null;
      state.societyMembersList = [];
    },
    getSocietyAcquisitionsSuccess(
      state: SocietyState,
      action: PayloadAction<GetSocietyAcquisitionsListSuccessPayload>
    ) {
      state.societyAcquisitionsList = action.payload.data;
      state.societyAcquisitionsLoading = false;
    },
    getSocietyAcquisitionsFailure(state: SocietyState, action: PayloadAction<string>) {
      state.societyAcquisitionsList = [];
      state.societyAcquisitionsLoading = false;
      state.societyAcquisitionsError = action.payload;
    },
    clearSocietyAcquisitionsList(state: SocietyState) {
      state.societyAcquisitionsLoading = false;
      state.societyAcquisitionsError = null;
      state.societyAcquisitionsList = [];
    },
    getSocietyDisposalsSuccess(state: SocietyState, action: PayloadAction<GetSocietyDisposalsSuccessPayload>) {
      state.societyDisposalsList = action.payload.data;
      state.societyDisposalsLoading = false;
    },
    getSocietyDisposalsFailure(state: SocietyState, action: PayloadAction<string>) {
      state.societyDisposalsError = action.payload;
      state.societyDisposalsLoading = false;
      state.societyDisposalsList = [];
    },
    clearSocietyDisposalsList(state: SocietyState) {
      state.societyDisposalsLoading = false;
      state.societyDisposalsError = null;
      state.societyDisposalsList = [];
    },
  },
});

export const societyActions = {
  setSocietyMembersLoading: societySlice.actions.setSocietyMembersLoading,
  setSocietyAcquisitionsLoading: societySlice.actions.setSocietyAcquisitionsLoading,
  setSocietyDisposalsLoading: societySlice.actions.setSocietyDisposalsLoading,
  getSocietyMembersSuccess: societySlice.actions.getSocietyMembersSuccess,
  getSocietyMembersFailure: societySlice.actions.getSocietyMembersFailure,
  clearSocietyMembersList: societySlice.actions.clearSocietyMembersList,
  getSocietyAcquisitionsSuccess: societySlice.actions.getSocietyAcquisitionsSuccess,
  getSocietyAcquisitionsFailure: societySlice.actions.getSocietyAcquisitionsFailure,
  clearSocietyAcquisitionsList: societySlice.actions.clearSocietyAcquisitionsList,
  getSocietyDisposalsSuccess: societySlice.actions.getSocietyDisposalsSuccess,
  getSocietyDisposalsFailure: societySlice.actions.getSocietyDisposalsFailure,
  clearSocietyDisposalsList: societySlice.actions.clearSocietyDisposalsList,
  getSocietyMembersRequest: createAction<GetSocietyMembersRequestPayload>(`${storeName}/getSocietyMembersRequest`),
  getSocietyAcquisitionsRequest: createAction<GetSocietyAcquisitionsListRequestPayload>(
    `${storeName}/getSocietyAcquisitionsRequest`
  ),
  getSocietyDisposalsRequest: createAction<GetSocietyDisposalsRequestPayload>(
    `${storeName}/getSocietyDisposalsRequest`
  ),
};

export const societyReducer = societySlice.reducer;
