import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateMultiPropertyCampaignRequestPayload,
  ManageMultiPropertyCampaignState,
  ManageMultiPropertyTemplate,
  ScheduleMultiPropertyEmailCampaignPayload,
  SendTestMultiPropertyEmailCampaignPayload,
} from '@redux/types/campaigns/manageCampaignMultiPropertyTypes';
import { GetCampaignRecipientsSuccessPayload } from '@redux/types/campaigns/manageCampaignSinglePropertyTypes';
import {
  DeleteEmailCampaignPayload,
  GetCampaignRecipientsRequestPayload,
  UnScheduleEmailCampaignPayload,
} from '@redux/types/campaigns/manageCampaignTypes';
import { CampaignStatus } from '@shared/models/campaign/statuses';

const storeName = 'multiPropertyCampaign';

const initialState: ManageMultiPropertyCampaignState = {
  loading: false,
  email_templates: [],
  marketing_lists: [],
  recipientsList: [],
  disposals: [],
  recipientsLoading: false,
  removeRecentlyContacted: null,
  assignedUsersLoading: false,
  assigned_users: [],
  createLoading: false,
  unScheduleLoading: false,
  scheduleLoading: false,
  deleteLoading: false,
  sendTestLoading: false,
  sendTestSuccess: false,
};

const manageCampaignSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setTemplateLoading(state: ManageMultiPropertyCampaignState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.email_templates = [];
      state.marketing_lists = [];
      state.campaign = undefined;
      state.disposals = [];
      state.assigned_users = [];
      state.status = undefined;
      state.error = undefined;
      state.createError = undefined;
      state.scheduleError = undefined;
      state.recipientsPagination = undefined;
    },
    getTemplateSuccess(state: ManageMultiPropertyCampaignState, action: PayloadAction<ManageMultiPropertyTemplate>) {
      state.loading = false;
      state.email_templates = action.payload.email_templates;
      state.marketing_lists = action.payload.marketing_lists;
      state.campaign = action.payload?.campaign;
      state.disposals = action.payload?.disposal_data?.disposals || [];
      state.assigned_users = action.payload?.disposal_data?.assigned_users || [];
      state.status = action.payload.status;
    },
    getTemplateFailure(state: ManageMultiPropertyCampaignState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
    clearTemplate(state: ManageMultiPropertyCampaignState) {
      state.campaign = undefined;
      state.email_templates = [];
      state.marketing_lists = [];
      state.status = undefined;
    },
    setRecipientsLoading(state: ManageMultiPropertyCampaignState, action: PayloadAction<boolean>) {
      state.recipientsLoading = action.payload;
      state.recipientsList = [];
      state.recipientsPagination = undefined;
    },
    getRecipientsSuccess(
      state: ManageMultiPropertyCampaignState,
      action: PayloadAction<GetCampaignRecipientsSuccessPayload>
    ) {
      state.recipientsLoading = false;
      state.recipientsList = action.payload.data;
      state.recipientsPagination = action.payload.meta;
    },
    getRecipientsFailure(state: ManageMultiPropertyCampaignState, action: PayloadAction<string>) {
      state.recipientsError = action.payload;
      state.recipientsLoading = false;
      state.recipientsList = [];
      state.recipientsPagination = undefined;
    },
    clearRecipientsList(state: ManageMultiPropertyCampaignState) {
      state.recipientsList = [];
      state.recipientsPagination = undefined;
      state.removeRecentlyContacted = null;
    },
    setCreatingLoading(state: ManageMultiPropertyCampaignState, action: PayloadAction<boolean>) {
      state.createLoading = action.payload;
    },
    createEmailCampaignSuccess(state: ManageMultiPropertyCampaignState) {
      state.createLoading = false;
    },
    createEmailCampaignFailure(
      state: ManageMultiPropertyCampaignState,
      action: PayloadAction<ManageMultiPropertyCampaignState['createError']>
    ) {
      state.createLoading = false;
      state.createError = action.payload;
    },
    setSendTestEmailLoading(state: ManageMultiPropertyCampaignState, action: PayloadAction<boolean>) {
      state.sendTestLoading = action.payload;
      state.sendTestSuccess = false;
      state.sendTestError = undefined;
    },
    sendTestEmailSuccess(state: ManageMultiPropertyCampaignState) {
      state.sendTestLoading = false;
      state.sendTestSuccess = true;
      state.sendTestError = undefined;
    },
    sendTestEmailFailure(state: ManageMultiPropertyCampaignState, action: PayloadAction<string>) {
      state.sendTestLoading = false;
      state.sendTestSuccess = false;
      state.sendTestError = action.payload;
    },
    clearSendTest(state: ManageMultiPropertyCampaignState) {
      state.sendTestLoading = false;
      state.sendTestSuccess = false;
      state.sendTestError = undefined;
    },
    setUnScheduleLoading(state: ManageMultiPropertyCampaignState, action: PayloadAction<boolean>) {
      state.unScheduleLoading = action.payload;
      state.unScheduleError = undefined;
    },
    unScheduleSuccess(state: ManageMultiPropertyCampaignState) {
      state.unScheduleLoading = false;

      if (state.status) {
        state.status = CampaignStatus.unscheduled;
      }
    },
    unScheduleFailure(state: ManageMultiPropertyCampaignState, action: PayloadAction<string>) {
      state.unScheduleLoading = false;
      state.unScheduleError = action.payload;
    },
    clearUnScheduleError(state: ManageMultiPropertyCampaignState) {
      state.unScheduleError = undefined;
    },
    setScheduleLoading(state: ManageMultiPropertyCampaignState, action: PayloadAction<boolean>) {
      state.scheduleLoading = action.payload;
      state.scheduleError = undefined;
    },
    scheduleSuccess(state: ManageMultiPropertyCampaignState) {
      state.scheduleLoading = false;
    },
    scheduleFailure(
      state: ManageMultiPropertyCampaignState,
      action: PayloadAction<ManageMultiPropertyCampaignState['scheduleError']>
    ) {
      state.scheduleError = action.payload;
      state.scheduleLoading = false;
    },
    setDeleteLoading(state: ManageMultiPropertyCampaignState, action: PayloadAction<boolean>) {
      state.deleteLoading = action.payload;
      state.deleteError = undefined;
    },
    deleteSuccess(state: ManageMultiPropertyCampaignState) {
      state.deleteLoading = false;
    },
    deleteFailure(state: ManageMultiPropertyCampaignState, action: PayloadAction<string>) {
      state.deleteError = action.payload;
      state.deleteLoading = false;
    },
    clearDeletingError(state: ManageMultiPropertyCampaignState) {
      state.deleteError = undefined;
    },
  },
});

export const manageMultiPropertyCampaignActions = {
  setTemplateLoading: manageCampaignSlice.actions.setTemplateLoading,
  getTemplateSuccess: manageCampaignSlice.actions.getTemplateSuccess,
  getTemplateFailure: manageCampaignSlice.actions.getTemplateFailure,
  getTemplateRequest: createAction<Id | undefined>(`${storeName}/getTemplateRequest`),
  clearTemplate: manageCampaignSlice.actions.clearTemplate,

  setRecipientsLoading: manageCampaignSlice.actions.setRecipientsLoading,
  getRecipientsSuccess: manageCampaignSlice.actions.getRecipientsSuccess,
  getRecipientsFailure: manageCampaignSlice.actions.getRecipientsFailure,
  getRecipientsRequest: createAction<GetCampaignRecipientsRequestPayload>(`${storeName}/getRecipientsRequest`),
  clearRecipientsList: manageCampaignSlice.actions.clearRecipientsList,

  setCreatingLoading: manageCampaignSlice.actions.setCreatingLoading,
  createEmailCampaignSuccess: manageCampaignSlice.actions.createEmailCampaignSuccess,
  createEmailCampaignFailure: manageCampaignSlice.actions.createEmailCampaignFailure,
  createEmailCampaignRequest: createAction<CreateMultiPropertyCampaignRequestPayload>(
    `${storeName}/createEmailCampaignRequest`
  ),

  setSendTestEmailLoading: manageCampaignSlice.actions.setSendTestEmailLoading,
  sendTestEmailSuccess: manageCampaignSlice.actions.sendTestEmailSuccess,
  sendTestEmailFailure: manageCampaignSlice.actions.sendTestEmailFailure,
  sendTestEmailRequest: createAction<SendTestMultiPropertyEmailCampaignPayload>(`${storeName}/sendTestEmailRequest`),
  clearSendTest: manageCampaignSlice.actions.clearSendTest,

  setUnScheduleLoading: manageCampaignSlice.actions.setUnScheduleLoading,
  unScheduleSuccess: manageCampaignSlice.actions.unScheduleSuccess,
  unScheduleFailure: manageCampaignSlice.actions.unScheduleFailure,
  unScheduleRequest: createAction<UnScheduleEmailCampaignPayload>(`${storeName}/unScheduleRequest`),
  clearUnScheduleError: manageCampaignSlice.actions.clearUnScheduleError,

  setScheduleLoading: manageCampaignSlice.actions.setScheduleLoading,
  scheduleSuccess: manageCampaignSlice.actions.scheduleSuccess,
  scheduleFailure: manageCampaignSlice.actions.scheduleFailure,
  scheduleRequest: createAction<ScheduleMultiPropertyEmailCampaignPayload>(`${storeName}/scheduleRequest`),

  setDeleteLoading: manageCampaignSlice.actions.setDeleteLoading,
  deleteSuccess: manageCampaignSlice.actions.deleteSuccess,
  deleteFailure: manageCampaignSlice.actions.deleteFailure,
  deleteRequest: createAction<DeleteEmailCampaignPayload>(`${storeName}/deleteRequest`),
  clearDeletingError: manageCampaignSlice.actions.clearDeletingError,
};

export const manageMultiPropertyCampaignReducer = manageCampaignSlice.reducer;
