import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetRequirementsRequestPayload,
  GetRequirementsSuccessPayload,
  RequirementsState,
} from '@redux/types/common/requirements/requirementsTypes';

const storeName = 'requirements';

const initialState: RequirementsState = {
  requirementsLoading: false,
  error: null,
  requirements: [],
  pagination: undefined,
};

const requirementsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setRequirementsLoading(state: RequirementsState, action: PayloadAction<boolean>) {
      state.requirementsLoading = action.payload;
    },
    getRequirementsSuccess(state: RequirementsState, action: PayloadAction<GetRequirementsSuccessPayload>) {
      state.requirements = action.payload.data;
      state.pagination = action.payload.meta.pagination;
      state.requirementsLoading = false;
    },
    getRequirementsFailure(state: RequirementsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.requirementsLoading = false;
      state.requirements = [];
    },
    clearRequirementsList(state: RequirementsState) {
      state.requirements = [];
    },
  },
});

export const requirementsActions = {
  setRequirementsLoading: requirementsSlice.actions.setRequirementsLoading,
  getRequirementsSuccess: requirementsSlice.actions.getRequirementsSuccess,
  getRequirementsFailure: requirementsSlice.actions.getRequirementsFailure,
  clearRequirementsList: requirementsSlice.actions.clearRequirementsList,
  getRequirementsRequest: createAction<GetRequirementsRequestPayload>(`${storeName}/getRequirementsRequest`),
};

export const requirementsReducer = requirementsSlice.reducer;
