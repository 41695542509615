import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateSinglePropertyCampaignRequestPayload,
  GetCampaignRecipientsSuccessPayload,
  GetManageSinglePropertyTemplatePayload,
  ManageCampaignState,
  ManageSinglePropertyTemplate,
  ScheduleSinglePropertyEmailCampaignPayload,
  SendTestSinglePropertyEmailCampaignPayload,
} from '@redux/types/campaigns/manageCampaignSinglePropertyTypes';
import {
  DeleteEmailCampaignPayload,
  GetCampaignRecipientsRequestPayload,
  UnScheduleEmailCampaignPayload,
} from '@redux/types/campaigns/manageCampaignTypes';
import { CampaignStatus } from '@shared/models/campaign/statuses';

const storeName = 'singlePropertyCampaign';

const initialState: ManageCampaignState = {
  loading: false,
  recipientsLoading: false,
  recipientsList: [],
  createLoading: false,
  sendTestLoading: false,
  sendTestSuccess: false,
  removeRecentlyContacted: null,
  unScheduleLoading: false,
  scheduleLoading: false,
  deleteLoading: false,
};

const manageCampaignSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setEmailTemplateLoading(state: ManageCampaignState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.emailTemplate = undefined;
      state.error = undefined;
      state.createError = undefined;
      state.scheduleError = undefined;
      state.recipientsMeta = undefined;
    },
    getEmailTemplateSuccess(state: ManageCampaignState, action: PayloadAction<ManageSinglePropertyTemplate>) {
      state.emailTemplate = action.payload;
      state.loading = false;
      state.error = undefined;
    },
    getEmailTemplateFailure(state: ManageCampaignState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.emailTemplate = undefined;
    },
    clearTemplate(state: ManageCampaignState) {
      state.emailTemplate = undefined;
    },
    setRecipientsLoading(state: ManageCampaignState, action: PayloadAction<boolean>) {
      state.recipientsLoading = action.payload;
      state.recipientsList = [];
      state.recipientsMeta = undefined;
    },
    getRecipientsSuccess(state: ManageCampaignState, action: PayloadAction<GetCampaignRecipientsSuccessPayload>) {
      state.recipientsLoading = false;
      state.recipientsList = action.payload.data;
      state.recipientsMeta = action.payload.meta;
    },
    getRecipientsFailure(state: ManageCampaignState, action: PayloadAction<string>) {
      state.recipientsError = action.payload;
      state.recipientsLoading = false;
      state.recipientsList = [];
      state.recipientsMeta = undefined;
    },
    clearRecipientsList(state: ManageCampaignState) {
      state.recipientsList = [];
      state.recipientsMeta = undefined;
      state.removeRecentlyContacted = null;
    },
    setCreateLoading(state: ManageCampaignState, action: PayloadAction<boolean>) {
      state.createLoading = action.payload;
      state.createError = undefined;
    },
    createEmailCampaignSuccess(state: ManageCampaignState) {
      state.createLoading = false;
    },
    createEmailCampaignFailure(state: ManageCampaignState, action: PayloadAction<ManageCampaignState['createError']>) {
      state.createError = action.payload;
      state.createLoading = false;
    },
    setSendTestEmailLoading(state: ManageCampaignState, action: PayloadAction<boolean>) {
      state.sendTestLoading = action.payload;
      state.sendTestSuccess = false;
      state.sendTestError = undefined;
    },
    sendTestEmailSuccess(state: ManageCampaignState) {
      state.sendTestLoading = false;
      state.sendTestSuccess = true;
      state.sendTestError = undefined;
    },
    sendTestEmailFailure(state: ManageCampaignState, action: PayloadAction<string>) {
      state.sendTestLoading = false;
      state.sendTestSuccess = false;
      state.sendTestError = action.payload;
    },
    clearSendTest(state: ManageCampaignState) {
      state.sendTestLoading = false;
      state.sendTestSuccess = false;
      state.sendTestError = undefined;
    },
    setRemoveRecentlyContacted(state: ManageCampaignState, action: PayloadAction<boolean>) {
      state.removeRecentlyContacted = action.payload;
    },
    setUnScheduleLoading(state: ManageCampaignState, action: PayloadAction<boolean>) {
      state.unScheduleLoading = action.payload;
      state.unScheduleError = undefined;
    },
    unScheduleSuccess(state: ManageCampaignState) {
      state.unScheduleLoading = false;

      if (state.emailTemplate) {
        state.emailTemplate = { ...state.emailTemplate, status: CampaignStatus.unscheduled };
      }
    },
    unScheduleFailure(state: ManageCampaignState, action: PayloadAction<string>) {
      state.unScheduleLoading = false;
      state.unScheduleError = action.payload;
    },
    clearUnScheduleError(state: ManageCampaignState) {
      state.unScheduleError = undefined;
    },
    setScheduleLoading(state: ManageCampaignState, action: PayloadAction<boolean>) {
      state.scheduleLoading = action.payload;
      state.scheduleError = undefined;
    },
    scheduleSuccess(state: ManageCampaignState) {
      state.scheduleLoading = false;
    },
    scheduleFailure(state: ManageCampaignState, action: PayloadAction<ManageCampaignState['scheduleError']>) {
      state.scheduleError = action.payload;
      state.scheduleLoading = false;
    },
    setDeleteLoading(state: ManageCampaignState, action: PayloadAction<boolean>) {
      state.deleteLoading = action.payload;
      state.deleteError = undefined;
    },
    deleteSuccess(state: ManageCampaignState) {
      state.deleteLoading = false;
    },
    deleteFailure(state: ManageCampaignState, action: PayloadAction<string>) {
      state.deleteError = action.payload;
      state.deleteLoading = false;
    },
    clearDeletingError(state: ManageCampaignState) {
      state.deleteError = undefined;
    },
  },
});

export const manageSinglePropertyCampaignActions = {
  setEmailTemplateLoading: manageCampaignSlice.actions.setEmailTemplateLoading,
  getEmailTemplateSuccess: manageCampaignSlice.actions.getEmailTemplateSuccess,
  getEmailTemplateFailure: manageCampaignSlice.actions.getEmailTemplateFailure,
  getEmailTemplateRequest: createAction<GetManageSinglePropertyTemplatePayload>(`${storeName}/getEmailTemplateRequest`),
  clearTemplate: manageCampaignSlice.actions.clearTemplate,
  setRecipientsLoading: manageCampaignSlice.actions.setRecipientsLoading,
  getRecipientsSuccess: manageCampaignSlice.actions.getRecipientsSuccess,
  getRecipientsFailure: manageCampaignSlice.actions.getRecipientsFailure,
  getRecipientsRequest: createAction<GetCampaignRecipientsRequestPayload>(`${storeName}/getRecipientsRequest`),
  clearRecipientsList: manageCampaignSlice.actions.clearRecipientsList,
  setCreateLoading: manageCampaignSlice.actions.setCreateLoading,
  createEmailCampaignSuccess: manageCampaignSlice.actions.createEmailCampaignSuccess,
  createEmailCampaignFailure: manageCampaignSlice.actions.createEmailCampaignFailure,
  createEmailCampaignRequest: createAction<CreateSinglePropertyCampaignRequestPayload>(
    `${storeName}/createEmailCampaignRequest`
  ),
  setSendTestEmailLoading: manageCampaignSlice.actions.setSendTestEmailLoading,
  sendTestEmailSuccess: manageCampaignSlice.actions.sendTestEmailSuccess,
  sendTestEmailFailure: manageCampaignSlice.actions.sendTestEmailFailure,
  sendTestEmailRequest: createAction<SendTestSinglePropertyEmailCampaignPayload>(`${storeName}/sendTestEmailRequest`),
  clearSendTest: manageCampaignSlice.actions.clearSendTest,

  setRemoveRecentlyContacted: manageCampaignSlice.actions.setRemoveRecentlyContacted,
  setUnScheduleLoading: manageCampaignSlice.actions.setUnScheduleLoading,
  unScheduleSuccess: manageCampaignSlice.actions.unScheduleSuccess,
  unScheduleFailure: manageCampaignSlice.actions.unScheduleFailure,
  unScheduleRequest: createAction<UnScheduleEmailCampaignPayload>(`${storeName}/unScheduleRequest`),
  clearUnScheduleError: manageCampaignSlice.actions.clearUnScheduleError,

  setScheduleLoading: manageCampaignSlice.actions.setScheduleLoading,
  scheduleSuccess: manageCampaignSlice.actions.scheduleSuccess,
  scheduleFailure: manageCampaignSlice.actions.scheduleFailure,
  scheduleRequest: createAction<ScheduleSinglePropertyEmailCampaignPayload>(`${storeName}/scheduleRequest`),

  setDeleteLoading: manageCampaignSlice.actions.setDeleteLoading,
  deleteSuccess: manageCampaignSlice.actions.deleteSuccess,
  deleteFailure: manageCampaignSlice.actions.deleteFailure,
  deleteRequest: createAction<DeleteEmailCampaignPayload>(`${storeName}/deleteRequest`),
  clearDeletingError: manageCampaignSlice.actions.clearDeletingError,
};

export const manageSinglePropertyCampaignReducer = manageCampaignSlice.reducer;
