import { createAction, createSlice, PayloadAction, PayloadActionCreator, Slice } from '@reduxjs/toolkit';

import {
  ManageSavedViewRequestPayload,
  PinUnpinSavedViewRequestPayload,
  PinUnpinSavedViewSuccessPayload,
  SavedViewState,
  UpdateSavedViewRequestPayload,
} from '@redux/types/saved-views/common/savedViewsCommonTypes';
import { ISavedView } from '@shared/models/saved_view/savedView';

export interface SavedViewActions {
  getListRequest: PayloadActionCreator<void>;
  setListLoading: PayloadActionCreator<boolean>;
  getListSuccess: PayloadActionCreator<ISavedView[]>;
  getListFailure: PayloadActionCreator<string>;

  getUnpinnedViewRequest: PayloadActionCreator<Id>;
  setUnpinnedViewLoading: PayloadActionCreator<boolean>;
  getUnpinnedViewSuccess: PayloadActionCreator<ISavedView>;
  getUnpinnedViewFailure: PayloadActionCreator<string>;

  updateSavedViewsRequest: PayloadActionCreator<UpdateSavedViewRequestPayload>;
  setUpdateViewsRequestLoading: PayloadActionCreator<boolean>;
  updateViewsSuccess: PayloadActionCreator<void>;
  updateViewsFailure: PayloadActionCreator<string>;

  pinViewRequest: PayloadActionCreator<PinUnpinSavedViewRequestPayload>;
  pinViewSuccess: PayloadActionCreator<PinUnpinSavedViewSuccessPayload>;

  unpinViewRequest: PayloadActionCreator<PinUnpinSavedViewRequestPayload>;
  unpinViewSuccess: PayloadActionCreator<PinUnpinSavedViewSuccessPayload>;

  deleteViewRequest: PayloadActionCreator<Id>;
  deleteViewSuccess: PayloadActionCreator<Id>;
  editViewRequest: PayloadActionCreator<ManageSavedViewRequestPayload>;
  editViewSuccess: PayloadActionCreator<ISavedView>;
  setViewToUnpin: PayloadActionCreator<ISavedView>;
  createViewRequest: PayloadActionCreator<ManageSavedViewRequestPayload>;
  createViewSuccess: PayloadActionCreator<ISavedView>;
}

export const generateActions = (storeName: string, slice: Slice): SavedViewActions => ({
  getListRequest: createAction(`${storeName}/getListRequest`) as SavedViewActions['getListRequest'],
  setListLoading: slice.actions.setListLoading as SavedViewActions['setListLoading'],
  getListSuccess: slice.actions.getListSuccess as SavedViewActions['getListSuccess'],
  getListFailure: slice.actions.getListFailure as SavedViewActions['getListFailure'],
  getUnpinnedViewRequest: createAction(
    `${storeName}/getUnpinnedViewRequest`
  ) as SavedViewActions['getUnpinnedViewRequest'],
  setUnpinnedViewLoading: slice.actions.setUnpinnedViewLoading as SavedViewActions['setUnpinnedViewLoading'],
  getUnpinnedViewSuccess: slice.actions.getUnpinnedViewSuccess as SavedViewActions['getUnpinnedViewSuccess'],
  getUnpinnedViewFailure: slice.actions.getUnpinnedViewFailure as SavedViewActions['getUnpinnedViewFailure'],
  updateSavedViewsRequest: createAction(
    `${storeName}/updateSavedViewRequest`
  ) as SavedViewActions['updateSavedViewsRequest'],
  setUpdateViewsRequestLoading: slice.actions
    .setUpdateViewsRequestLoading as SavedViewActions['setUpdateViewsRequestLoading'],
  updateViewsSuccess: slice.actions.updateViewsSuccess as SavedViewActions['updateViewsSuccess'],
  updateViewsFailure: slice.actions.updateViewsFailure as SavedViewActions['updateViewsFailure'],

  pinViewRequest: createAction(`${storeName}/pinViewRequest`) as SavedViewActions['pinViewRequest'],
  pinViewSuccess: slice.actions.pinViewSuccess as SavedViewActions['pinViewSuccess'],

  unpinViewRequest: createAction(`${storeName}/unpinViewRequest`) as SavedViewActions['unpinViewRequest'],
  unpinViewSuccess: slice.actions.unpinViewSuccess as SavedViewActions['unpinViewSuccess'],

  deleteViewRequest: createAction(`${storeName}/deleteViewRequest`) as SavedViewActions['deleteViewRequest'],
  deleteViewSuccess: slice.actions.deleteViewSuccess as SavedViewActions['deleteViewSuccess'],
  editViewRequest: createAction(`${storeName}/editViewRequest`) as SavedViewActions['editViewRequest'],
  editViewSuccess: slice.actions.editViewSuccess as SavedViewActions['editViewSuccess'],
  setViewToUnpin: slice.actions.setViewToUnpin as SavedViewActions['setViewToUnpin'],
  createViewRequest: createAction(`${storeName}/createViewRequest`) as SavedViewActions['createViewRequest'],
  createViewSuccess: slice.actions.createViewSuccess as SavedViewActions['createViewSuccess'],
});

export const generateInitialState = (): SavedViewState => ({
  error: null,
  list: [],
  loading: false,
  unpinnedSavedViewError: null,
  unpinnedSavedViewLoading: false,
  updateError: null,
  updateLoading: false,
});

export const generateSlice = (storeName: string, initialState: SavedViewState): Slice =>
  createSlice({
    name: storeName,
    initialState,
    reducers: {
      setListLoading(state: SavedViewState, action: PayloadAction<boolean>) {
        state.loading = action.payload;
      },
      getListSuccess(state: SavedViewState, action: PayloadAction<ISavedView[]>) {
        state.list = action.payload;
        state.loading = false;
      },
      getListFailure(state: SavedViewState, action: PayloadAction<string>) {
        state.list = [];
        state.error = action.payload;
        state.loading = false;
      },
      setUnpinnedViewLoading(state: SavedViewState, action: PayloadAction<boolean>) {
        state.unpinnedSavedViewLoading = action.payload;
      },
      getUnpinnedViewSuccess(state: SavedViewState, action: PayloadAction<ISavedView>) {
        state.unpinnedSavedViewLoading = false;
        state.unpinnedSavedView = action.payload;
      },
      getUnpinnedViewFailure(state: SavedViewState, action: PayloadAction<string>) {
        state.unpinnedSavedViewError = action.payload;
        state.unpinnedSavedViewLoading = false;
      },
      setUpdateViewsRequestLoading(state: SavedViewState, action: PayloadAction<boolean>) {
        state.updateLoading = action.payload;
      },
      updateViewsSuccess(state: SavedViewState) {
        state.updateLoading = false;
      },
      updateViewsFailure(state: SavedViewState, action: PayloadAction<string>) {
        state.updateError = action.payload;
        state.updateLoading = false;
      },
      pinViewSuccess(state: SavedViewState, action: PayloadAction<PinUnpinSavedViewSuccessPayload>) {
        state.list = state.list.map((view) => {
          if (view.id === action.payload.view.id) {
            return { ...view, pinned: 1 };
          }

          return view;
        });

        if (action.payload.isActiveView || action.payload.view.id == state.unpinnedSavedView?.id) {
          state.unpinnedSavedView = undefined;
        }
      },
      unpinViewSuccess(state: SavedViewState, action: PayloadAction<PinUnpinSavedViewSuccessPayload>) {
        state.list = state.list.map((view: ISavedView) => {
          if (view.id === action.payload.view.id) {
            return { ...view, pinned: 0 };
          }

          return view;
        });

        state.unpinnedSavedView = action.payload.isActiveView ? action.payload.view : undefined;
      },
      deleteViewSuccess(state: SavedViewState, action: PayloadAction<Id>) {
        state.list = state.list.filter((view) => view.id !== action.payload);

        if (action.payload === state.unpinnedSavedView?.id) {
          state.unpinnedSavedView = undefined;
        }
      },
      editViewSuccess(state: SavedViewState, action: PayloadAction<ISavedView>) {
        state.list = state.list.map((view) => {
          if (view.id === action.payload.id) {
            return action.payload;
          }

          return view;
        });

        state.unpinnedSavedView = action.payload.id === state.unpinnedSavedView?.id ? action.payload : undefined;
      },
      setViewToUnpin(state: SavedViewState, action: PayloadAction<ISavedView>) {
        state.unpinnedSavedView = action.payload;
      },
      createViewSuccess(state: SavedViewState, action: PayloadAction<ISavedView>) {
        state.unpinnedSavedView = action.payload;
      },
    },
  });
