import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewInsightsRequirementsSaga,
  deleteSavedViewInsightsRequirementsSaga,
  editSavedViewInsightsRequirementsSaga,
  getInsightsRequirementsSaga,
  getSavedViewRequirementByIdSaga,
  pinInsightsRequirementsSavedViewsSaga,
  unpinInsightsRequirementsSavedViewsSaga,
  updatePinnedInsightsRequirementsSavedViewsSaga,
} from '@redux/sagas/saved_view/insights/savedViewRequirementInsightsSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewsRequirementInsights';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewsRequirementInsightsActions = generateActions(storeName, slice);

export const savedViewsRequirementInsightsReducer = slice.reducer;

export const savedViewsRequirementInsightsSagas: [ActionPattern, any][] = [
  [savedViewsRequirementInsightsActions.getListRequest.type, getInsightsRequirementsSaga],
  [savedViewsRequirementInsightsActions.getUnpinnedViewRequest.type, getSavedViewRequirementByIdSaga],
  [savedViewsRequirementInsightsActions.updateSavedViewsRequest.type, updatePinnedInsightsRequirementsSavedViewsSaga],
  [savedViewsRequirementInsightsActions.deleteViewRequest.type, deleteSavedViewInsightsRequirementsSaga],
  [savedViewsRequirementInsightsActions.editViewRequest.type, editSavedViewInsightsRequirementsSaga],
  [savedViewsRequirementInsightsActions.createViewRequest.type, createSavedViewInsightsRequirementsSaga],
  [savedViewsRequirementInsightsActions.pinViewRequest.type, pinInsightsRequirementsSavedViewsSaga],
  [savedViewsRequirementInsightsActions.unpinViewRequest.type, unpinInsightsRequirementsSavedViewsSaga],
];
