import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetMarketingListSuccessPayload,
  MarketingListState,
} from '@redux/types/common/marketing-list/marketingListTypes';

const storeName = 'marketingList';

const initialState: MarketingListState = {
  loading: false,
  list: [],
};

const marketingListSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setLoading(state: MarketingListState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getListSuccess(state: MarketingListState, action: PayloadAction<GetMarketingListSuccessPayload>) {
      state.list = action.payload.data;
      state.pagination = action.payload.meta.pagination;
      state.loading = false;
    },
    getListFailure(state: MarketingListState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.list = [];
    },
  },
});

export const marketingListActions = {
  setLoading: marketingListSlice.actions.setLoading,
  getListSuccess: marketingListSlice.actions.getListSuccess,
  getListFailure: marketingListSlice.actions.getListFailure,
  getListRequest: createAction(`${storeName}/getList`),
};

export const marketingListReducer = marketingListSlice.reducer;
