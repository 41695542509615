import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewSocietyDisposalInsightsSaga,
  deleteSavedViewSocietyDisposalInsightsSaga,
  editSavedViewSocietyDisposalInsightsSaga,
  getSocietyDisposalInsightsByIdSaga,
  getSocietyDisposalInsightsSaga,
  pinSocietyDisposalInsightsSavedViewsSaga,
  unpinSocietyDisposalInsightsSavedViewsSaga,
  updatePinnedSocietyDisposalInsightsSavedViewsSaga,
} from '@redux/sagas/saved_view/society-insights/savedViewSocietyDisposalInsightsSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewSocietyDisposalInsights';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewSocietyDisposalInsightsActions = generateActions(storeName, slice);

export const savedViewSocietyDisposalInsightsReducer = slice.reducer;

export const savedViewSocietyDisposalInsightsSagas: [ActionPattern, any][] = [
  [savedViewSocietyDisposalInsightsActions.getListRequest.type, getSocietyDisposalInsightsSaga],
  [savedViewSocietyDisposalInsightsActions.getUnpinnedViewRequest.type, getSocietyDisposalInsightsByIdSaga],
  [
    savedViewSocietyDisposalInsightsActions.updateSavedViewsRequest.type,
    updatePinnedSocietyDisposalInsightsSavedViewsSaga,
  ],
  [savedViewSocietyDisposalInsightsActions.deleteViewRequest.type, deleteSavedViewSocietyDisposalInsightsSaga],
  [savedViewSocietyDisposalInsightsActions.editViewRequest.type, editSavedViewSocietyDisposalInsightsSaga],
  [savedViewSocietyDisposalInsightsActions.createViewRequest.type, createSavedViewSocietyDisposalInsightsSaga],
  [savedViewSocietyDisposalInsightsActions.pinViewRequest.type, pinSocietyDisposalInsightsSavedViewsSaga],
  [savedViewSocietyDisposalInsightsActions.unpinViewRequest.type, unpinSocietyDisposalInsightsSavedViewsSaga],
];
