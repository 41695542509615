import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ContactsState,
  GetContactsRequestPayload,
  GetContactsSuccessPayload,
} from '@redux/types/common/contacts/contactsTypes';

const storeName = 'contacts';

const initialState: ContactsState = {
  contactsLoading: false,
  contacts: [],
  error: null,
  pagination: undefined,
};

const contactsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setContactsLoading(state: ContactsState, action: PayloadAction<boolean>) {
      state.contactsLoading = action.payload;
    },
    getContactsSuccess(state: ContactsState, action: PayloadAction<GetContactsSuccessPayload>) {
      state.contacts = action.payload.data;
      state.pagination = action.payload.meta.pagination;
      state.contactsLoading = false;
    },
    getContactsFailure(state: ContactsState, action: PayloadAction<string>) {
      state.contacts = [];
      state.error = action.payload;
      state.contactsLoading = false;
    },
    clearContactsList(state: ContactsState) {
      state.contacts = [];
      state.pagination = undefined;
    },
  },
});

export const contactsActions = {
  setContactsLoading: contactsSlice.actions.setContactsLoading,
  getContactsSuccess: contactsSlice.actions.getContactsSuccess,
  getContactsFailure: contactsSlice.actions.getContactsFailure,
  clearContactsList: contactsSlice.actions.clearContactsList,
  getContactsRequest: createAction<GetContactsRequestPayload>(`${storeName}/getContactsRequest`),
};

export const contactsReducer = contactsSlice.reducer;
