import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CompaniesDnbState,
  GetCompaniesDnbRequestPayload,
  GetCompaniesDnbSuccessPayload,
} from '@redux/types/common/companies-dnb/companiesDnbTypes';

const storeName = 'companiesDnb';

const initialState: CompaniesDnbState = {
  error: null,
  loading: false,
  list: [],
};

const companiesDnbSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setListLoading(state: CompaniesDnbState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.list = [];
    },
    getListSuccess(state: CompaniesDnbState, action: PayloadAction<GetCompaniesDnbSuccessPayload>) {
      state.list = action.payload.data;
      state.meta = action.payload.meta;
      state.loading = false;
    },
    getListFailure(state: CompaniesDnbState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.list = [];
    },
    clearList(state: CompaniesDnbState) {
      state.list = [];
      state.meta = undefined;
      state.error = null;
    },
  },
});

export const companiesDnbActions = {
  setListLoading: companiesDnbSlice.actions.setListLoading,
  getListSuccess: companiesDnbSlice.actions.getListSuccess,
  getListFailure: companiesDnbSlice.actions.getListFailure,
  clearList: companiesDnbSlice.actions.clearList,
  getListRequest: createAction<GetCompaniesDnbRequestPayload>(`${storeName}/getCompaniesDnbRequest`),
};

export const companiesDnbReducer = companiesDnbSlice.reducer;
