import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewCompaniesSaga,
  deleteSavedViewCompaniesSaga,
  editSavedViewCompaniesSaga,
  getCompaniesSavedViewById,
  getCompaniesSavedViewsSaga,
  pinSavedViewCompanySaga,
  unpinSavedViewCompanySaga,
  updatePinnedCompaniesSavedViewsSaga,
} from '@redux/sagas/saved_view/companies/companiesSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewCompanies';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewsCompaniesActions = generateActions(storeName, slice);

export const companiesReducer = slice.reducer;

export const savedViewsCompaniesSagas: [ActionPattern, any][] = [
  [savedViewsCompaniesActions.getListRequest.type, getCompaniesSavedViewsSaga],
  [savedViewsCompaniesActions.getUnpinnedViewRequest.type, getCompaniesSavedViewById],
  [savedViewsCompaniesActions.updateSavedViewsRequest.type, updatePinnedCompaniesSavedViewsSaga],
  [savedViewsCompaniesActions.deleteViewRequest.type, deleteSavedViewCompaniesSaga],
  [savedViewsCompaniesActions.editViewRequest.type, editSavedViewCompaniesSaga],
  [savedViewsCompaniesActions.createViewRequest.type, createSavedViewCompaniesSaga],
  [savedViewsCompaniesActions.pinViewRequest.type, pinSavedViewCompanySaga],
  [savedViewsCompaniesActions.unpinViewRequest.type, unpinSavedViewCompanySaga],
];
