import { combineReducers } from 'redux';

import { companiesManageReducer } from '@redux/reducers/crm/companies/manage/companiesManageReducer';

import { contactManageReducer } from './contacts/manage/contactManageReducer';

const crmReducer = combineReducers({
  contacts: combineReducers({
    manage: contactManageReducer,
  }),
  companies: combineReducers({
    manage: companiesManageReducer,
  }),
});

export default crmReducer;
