import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AreaTeamsState } from '@redux/types/common/area-teams/areaTeamsTypes';
import { SocietySegments } from '@shared/types/common/segment';

const storeName = 'areaTeams';

const initialState: AreaTeamsState = {
  loading: false,
  error: null,
  teams: [],
};

const areaTeamsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setAreaTeamsLoading(state: AreaTeamsState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getAreaTeamsSuccess(state: AreaTeamsState, action: PayloadAction<SocietySegments[]>) {
      state.teams = action.payload;
      state.loading = false;
    },
    getAreaTeamsFailure(state: AreaTeamsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.teams = [];
    },
  },
});

export const areaTeamsActions = {
  setAreaTeamsLoading: areaTeamsSlice.actions.setAreaTeamsLoading,
  getAreaTeamsSuccess: areaTeamsSlice.actions.getAreaTeamsSuccess,
  getAreaTeamsFailure: areaTeamsSlice.actions.getAreaTeamsFailure,
  getAreaTeamsRequest: createAction(`${storeName}/getAreaTeamsRequest`),
};

export const areaTeamsReducer = areaTeamsSlice.reducer;
