import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const storeName = 'browserEvent';

const initialState = {
  browserEventsCount: 0,
  search: '',
  urlCount: 0,
  urlPath: '',
};

const browserEventSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    backForwardBrowserEvent(state, action: PayloadAction<string>) {
      state.search = action.payload;
      state.browserEventsCount = state.browserEventsCount + 1;
    },

    urlBrowserEvent(state) {
      state.urlCount = state.urlCount + 1;
      state.urlPath = window.location.href.split('?')[0] || '';
    },
  },
});

export const browserEventActions = {
  backForwardBrowserEvent: browserEventSlice.actions.backForwardBrowserEvent,
  urlBrowserEvent: browserEventSlice.actions.urlBrowserEvent,
};

export const browserEventReducer = browserEventSlice.reducer;
