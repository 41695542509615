import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConfigState } from '@redux/types/config/configTypes';
import { IUser } from '@shared/models/IUser';
import { ISavedView } from '@shared/models/saved_view/savedView';

const storeName = 'configStore';

const initialState: ConfigState = {
  user: {} as IUser,
};

const configSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Core
    setUser(state: ConfigState, action: PayloadAction<IUser>) {
      state.user = action.payload;
    },
    updateUserImage(state: ConfigState, action: PayloadAction<{ url: string }>) {
      state.user = {
        ...state.user,
        image: {
          ...state.user.image,
          url: action.payload.url,
        },
      };
    },
    updateUserInfo(state: ConfigState, action: PayloadAction<IUser>) {
      state.user = action.payload;
    },

    // Transactions -> Lettings
    deleteSavedViewLettingSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_letting_transactions: state.user.pinned_views_letting_transactions.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewLettingSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_letting_transactions: state.user.pinned_views_letting_transactions.map((view: ISavedView) => {
          if (view.id === action.payload.id) {
            return action.payload;
          }

          return view;
        }),
      };
    },

    // Campaigns
    deleteSavedViewCampaignsSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_email_campaigns: state.user.pinned_views_email_campaigns.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewCampaignsSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_email_campaigns: state.user.pinned_views_email_campaigns.map((view: ISavedView) => {
          if (view.id === action.payload.id) {
            return action.payload;
          }

          return view;
        }),
      };
    },

    // Transactions -> Sales
    deleteSavedViewSalesSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_sale_transactions: state.user.pinned_views_sale_transactions.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewSalesSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_sale_transactions: state.user.pinned_views_sale_transactions.map((view: ISavedView) => {
          if (view.id === action.payload.id) {
            return action.payload;
          }

          return view;
        }),
      };
    },

    // Disposals
    deleteSavedViewDisposalsSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_disposals: state.user.pinned_views_disposals.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewDisposalsSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_disposals: state.user.pinned_views_disposals.map((view: ISavedView) => {
          if (view.id === action.payload.id) {
            return action.payload;
          }

          return view;
        }),
      };
    },

    // Companies
    deleteSavedViewCompaniesSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_crm_companies: state.user.pinned_views_crm_companies.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewCompaniesSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_crm_companies: state.user.pinned_views_crm_companies.map((view: ISavedView) => {
          if (view.id === action.payload.id) {
            return action.payload;
          }

          return view;
        }),
      };
    },

    // Contacts
    deleteSavedViewContactsSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_crm_contacts: state.user.pinned_views_crm_contacts.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewContactsSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_crm_contacts: state.user.pinned_views_crm_contacts.map((view: ISavedView) => {
          if (view.id === action.payload.id) {
            return action.payload;
          }

          return view;
        }),
      };
    },

    // Requirement Insights
    deleteSavedViewInsightsRequirementsSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_requirement_insights: state.user.pinned_views_requirement_insights.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewInsightsRequirementsSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_requirement_insights: state.user.pinned_views_requirement_insights.map((view: ISavedView) => {
          if (view.id === action.payload.id) {
            return action.payload;
          }

          return view;
        }),
      };
    },

    // Society Acquisition Insights
    deleteSavedViewSocietyAcquisitionInsightsSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_society_acquisition_insights: state.user.pinned_views_society_acquisition_insights.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewSocietyAcquisitionInsightsSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_society_acquisition_insights: state.user.pinned_views_society_acquisition_insights.map(
          (view: ISavedView) => {
            if (view.id === action.payload.id) {
              return action.payload;
            }

            return view;
          }
        ),
      };
    },

    // Society Disposal Insights
    deleteSavedViewSocietyDisposalInsightsSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_society_disposal_insights: state.user.pinned_views_society_disposal_insights.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewSocietyDisposalInsightsSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_society_disposal_insights: state.user.pinned_views_society_disposal_insights.map(
          (view: ISavedView) => {
            if (view.id === action.payload.id) {
              return action.payload;
            }

            return view;
          }
        ),
      };
    },

    // Society Viewings Insights
    deleteSavedViewViewingsInsightsSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_viewing_insights: state.user.pinned_views_viewing_insights.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewViewingsInsightsSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_viewing_insights: state.user.pinned_views_viewing_insights.map((view: ISavedView) => {
          if (view.id === action.payload.id) {
            return action.payload;
          }

          return view;
        }),
      };
    },

    // Society Letting Insights
    deleteSavedViewLettingInsightsSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_letting_transaction_insights: state.user.pinned_views_letting_transaction_insights.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewLettingInsightsSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_letting_transaction_insights: state.user.pinned_views_letting_transaction_insights.map(
          (view: ISavedView) => {
            if (view.id === action.payload.id) {
              return action.payload;
            }

            return view;
          }
        ),
      };
    },

    // Society Sale Insights
    deleteSavedViewSaleInsightsSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_sale_transaction_insights: state.user.pinned_views_sale_transaction_insights.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewSaleInsightsSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_sale_transaction_insights: state.user.pinned_views_sale_transaction_insights.map(
          (view: ISavedView) => {
            if (view.id === action.payload.id) {
              return action.payload;
            }

            return view;
          }
        ),
      };
    },

    // Society Disposals Insights
    deleteSavedViewDisposalInsightsSuccess(state: ConfigState, action: PayloadAction<Id>) {
      state.user = {
        ...state.user,
        pinned_views_disposal_insights: state.user.pinned_views_disposal_insights.filter(
          (view: ISavedView) => view.id !== action.payload
        ),
      };
    },
    editSavedViewDisposalInsightsSuccess(state: ConfigState, action: PayloadAction<ISavedView>) {
      state.user = {
        ...state.user,
        pinned_views_disposal_insights: state.user.pinned_views_disposal_insights.map((view: ISavedView) => {
          if (view.id === action.payload.id) {
            return action.payload;
          }

          return view;
        }),
      };
    },
  },
});

export const configActions = {
  // Core
  setUser: configSlice.actions.setUser,
  updateUserImage: configSlice.actions.updateUserImage,
  updateUserInfo: configSlice.actions.updateUserInfo,

  // Transactions -> Lettings
  deleteSavedViewLettingSuccess: configSlice.actions.deleteSavedViewLettingSuccess,
  editSavedViewLettingSuccess: configSlice.actions.editSavedViewLettingSuccess,

  // Campaigns
  deleteSavedViewCampaignsSuccess: configSlice.actions.deleteSavedViewCampaignsSuccess,
  editSavedViewCampaignsSuccess: configSlice.actions.editSavedViewCampaignsSuccess,

  // Transactions -> Sales
  deleteSavedViewSalesSuccess: configSlice.actions.deleteSavedViewSalesSuccess,
  editSavedViewSalesSuccess: configSlice.actions.editSavedViewSalesSuccess,

  // Disposals
  deleteSavedViewDisposalsSuccess: configSlice.actions.deleteSavedViewDisposalsSuccess,
  editSavedViewDisposalsSuccess: configSlice.actions.editSavedViewDisposalsSuccess,

  // Companies
  deleteSavedViewCompaniesSuccess: configSlice.actions.deleteSavedViewCompaniesSuccess,
  editSavedViewCompaniesSuccess: configSlice.actions.editSavedViewCompaniesSuccess,

  // Contacts
  deleteSavedViewContactsSuccess: configSlice.actions.deleteSavedViewContactsSuccess,
  editSavedViewContactsSuccess: configSlice.actions.editSavedViewContactsSuccess,

  // Requirement Insights
  deleteSavedViewInsightsRequirementsSuccess: configSlice.actions.deleteSavedViewInsightsRequirementsSuccess,
  editSavedViewInsightsRequirementsSuccess: configSlice.actions.editSavedViewInsightsRequirementsSuccess,

  // Society Acquisition Insights
  deleteSavedViewSocietyAcquisitionInsightsSuccess:
    configSlice.actions.deleteSavedViewSocietyAcquisitionInsightsSuccess,
  editSavedViewSocietyAcquisitionInsightsSuccess: configSlice.actions.editSavedViewSocietyAcquisitionInsightsSuccess,

  // Society Disposal Insights
  deleteSavedViewSocietyDisposalInsightsSuccess: configSlice.actions.deleteSavedViewSocietyDisposalInsightsSuccess,
  editSavedViewSocietyDisposalInsightsSuccess: configSlice.actions.editSavedViewSocietyDisposalInsightsSuccess,

  // Society Viewing Insights
  deleteSavedViewViewingsInsightsSuccess: configSlice.actions.deleteSavedViewViewingsInsightsSuccess,
  editSavedViewViewingsInsightsSuccess: configSlice.actions.editSavedViewViewingsInsightsSuccess,

  // Society Letting Insights
  deleteSavedViewLettingInsightsSuccess: configSlice.actions.deleteSavedViewLettingInsightsSuccess,
  editSavedViewLettingInsightsSuccess: configSlice.actions.editSavedViewLettingInsightsSuccess,

  //Disposal Insights
  deleteSavedViewDisposalInsightsSuccess: configSlice.actions.deleteSavedViewDisposalInsightsSuccess,
  editSavedViewDisposalInsightsSuccess: configSlice.actions.editSavedViewDisposalInsightsSuccess,

  // Society Sale Insights
  deleteSavedViewSaleInsightsSuccess: configSlice.actions.deleteSavedViewSaleInsightsSuccess,
  editSavedViewSaleInsightsSuccess: configSlice.actions.editSavedViewSaleInsightsSuccess,
};

export const configReducer = configSlice.reducer;
