import { ActionPattern } from 'redux-saga/effects';

import {
  generateActions,
  generateInitialState,
  generateSlice,
} from '@redux/reducers/saved_views/savedViewsReducer.utils';
import {
  createSavedViewSalesSaga,
  deleteSavedViewSalesSaga,
  editSavedViewSalesSaga,
  getSalesByIdSaga,
  getSalesSaga,
  pinSalesSavedViewsSaga,
  unpinSalesSavedViewsSaga,
  updatePinnedSalesSavedViewsSaga,
} from '@redux/sagas/saved_view/sales/salesSaga';
import { SavedViewState } from '@redux/types/saved-views/common/savedViewsCommonTypes';

const storeName = 'savedViewSales';

const initialState: SavedViewState = generateInitialState();

const slice = generateSlice(storeName, initialState);

export const savedViewsSalesActions = generateActions(storeName, slice);

export const salesReducer = slice.reducer;

export const savedViewsSalesSagas: [ActionPattern, any][] = [
  [savedViewsSalesActions.getListRequest.type, getSalesSaga],
  [savedViewsSalesActions.getUnpinnedViewRequest.type, getSalesByIdSaga],
  [savedViewsSalesActions.updateSavedViewsRequest.type, updatePinnedSalesSavedViewsSaga],
  [savedViewsSalesActions.deleteViewRequest.type, deleteSavedViewSalesSaga],
  [savedViewsSalesActions.editViewRequest.type, editSavedViewSalesSaga],
  [savedViewsSalesActions.createViewRequest.type, createSavedViewSalesSaga],
  [savedViewsSalesActions.pinViewRequest.type, pinSalesSavedViewsSaga],
  [savedViewsSalesActions.unpinViewRequest.type, unpinSalesSavedViewsSaga],
];
