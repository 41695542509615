import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TasksState } from '@redux/types/diary/tasksTypes';
import { InsightsViewingTask } from '@shared/models/insightsViewing/insightsViewingTask';

const storeName = 'manageTasks';

const initialState: TasksState = {
  loading: false,
};

export interface GetTasksRequestPayload {
  data: InsightsViewingTask;
}

const manageTasksSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setManageTasksLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.data = null;
    },
    getManageTasksSuccess(state, action: PayloadAction<InsightsViewingTask>) {
      state.data = action.payload;
      state.loading = false;
    },
    geManageTasksFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const manageTasksActions = {
  setManageTasksLoading: manageTasksSlice.actions.setManageTasksLoading,
  getManageTasksSuccess: manageTasksSlice.actions.getManageTasksSuccess,
  getManageTasksFailure: manageTasksSlice.actions.geManageTasksFailure,
  createTasksRequest: createAction<GetTasksRequestPayload>(`${storeName}/createTasksRequest`),
};

export const manageTasksSliceReducer = manageTasksSlice.reducer;
