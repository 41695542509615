import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetSaleInsightsRequestPayload,
  GetSaleInsightsSuccess,
  SaleInsightsState,
} from '@redux/types/insights/sale/saleInsightsTypes';

const storeName = 'saleInsights';

const initialState: SaleInsightsState = {
  error: null,
  loading: false,
  reports: [],
  societiesInvalid: false,
};

const saleInsightsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setListLoading(state: SaleInsightsState, action: PayloadAction<boolean>) {
      state.error = null;
      state.loading = action.payload;
      state.reports = [];
      state.societiesInvalid = false;
      state.meta = undefined;
    },
    setListSocietiesInvalid(state: SaleInsightsState, action: PayloadAction<boolean>) {
      state.societiesInvalid = action.payload;
    },
    getListSuccess(state: SaleInsightsState, action: PayloadAction<GetSaleInsightsSuccess>) {
      state.loading = false;
      state.reports = action.payload.data;
      state.meta = action.payload.meta;
    },
    getListFailure(state: SaleInsightsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const saleInsightsActions = {
  setListLoading: saleInsightsSlice.actions.setListLoading,
  setListSocietiesInvalid: saleInsightsSlice.actions.setListSocietiesInvalid,
  getListSuccess: saleInsightsSlice.actions.getListSuccess,
  getListFailure: saleInsightsSlice.actions.getListFailure,
  getListRequest: createAction<GetSaleInsightsRequestPayload>(`${storeName}/getListRequest`),
};

export const saleInsightsReducer = saleInsightsSlice.reducer;
