import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProfileState, UpdateProfileRequestPayload } from '@redux/types/user/profile/profileTypes';

const storeName = 'profile';

const initialState: ProfileState = {
  updatingProfile: false,
};

const profileSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setUpdateProfileLoading(state: ProfileState, action: PayloadAction<boolean>) {
      state.updatingProfile = action.payload;
    },
    updateProfileSuccess(state: ProfileState) {
      state.updatingProfile = false;
    },
    updateProfileError(state: ProfileState, action: PayloadAction<string>) {
      state.updatingProfile = false;
      state.updateProfileError = action.payload;
    },
  },
});

export const profileActions = {
  setUpdateProfileLoading: profileSlice.actions.setUpdateProfileLoading,
  updateProfileSuccess: profileSlice.actions.updateProfileSuccess,
  updateProfileError: profileSlice.actions.updateProfileError,
  updateProfileRequest: createAction<UpdateProfileRequestPayload>(`${storeName}/updateProfileRequest`),
};

export const profileReducer = profileSlice.reducer;
