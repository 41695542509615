import { combineReducers } from 'redux';

import { disposalManageInsightsReducer } from './disposalInsights/disposalInsightsReducer';
import { disposalMarketplacePerformanceReducer } from './disposalMarketplacePerformance/marketplacePerformanceReducer';

const disposalsReducer = combineReducers({
  insights: disposalManageInsightsReducer,
  marketplacePerformance: disposalMarketplacePerformanceReducer,
});

export default disposalsReducer;
